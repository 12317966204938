import React, { useEffect, useState, memo, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from './Base';
import { RowBetween, BaseRow } from '../Row';
import { SquareBtn } from '../Button';
import SingleCoinIcon from '../Icon/Single';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { commafy, getCoinUrlByNameFn } from '../../utils';
import Loading from '../Loading';
import { isMobile } from 'react-device-detect';
import { WalletContext } from '../../utils/wallet';

const BigNumber = require('bignumber.js');

const colorBlue = '#5B86E5';
const colorBlack = '#243559';

const StartTotal = styled(BaseRow)`
  flex-direction: column;
`;

const StartContent = styled(BaseRow)`
  padding: 0 32px;
  flex-direction: column;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;

const InpBorder = styled(RowBetween)`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
`;

const CoinInp = styled.div`
  color: ${colorBlack};
  padding: 0;
  font-size: 14px;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
`;

const Txt = styled.p`
  font-size: 16px;
  color: #999999;
  margin-bottom: 10px;
`;

const BtnGroup = styled.div`
  width: 100%;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  padding: 4px;
  margin-bottom: 10px;
`;

const CoinBtn = styled(SquareBtn)`
  height: auto;
  line-height: normal;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  background: ${(props) => (props.active ? '#fff' : 'none')};
  border-radius: 4px;
  color: ${colorBlack};
  align-items: center;
  border: none;
  font-size: 14px;
`;

const InpRight = styled.div`
  display: flex;
  align-items: center;
  color: ${colorBlack};
  font-size: 16px;
`;

const BtnContentGroup = styled(RowBetween)`
  width: 100%;
`;

const Btn = styled(SquareBtn)`
  width: 48%;
  border-radius: 8px;
  font-size: 16px;
  height: 58px;
  padding: 20px 0;
  border: none;
  background: ${(props) =>
    props.disabled ? '#999999' : props.color ? props.color : colorBlue};
  color: ${(props) => (props.disabled ? '#C1C1C1' : '#ffffff')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BuyBtn = styled(Btn)`
  background: white;
  border: 1px solid rgb(91 134 229);
  color: rgb(91 134 229);
`;

const Warn = styled.p`
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: #f05f5f;

  ${isMobile &&
  css`
    padding: 1.25rem 1.25rem 0;
  `}
`;

const TimeLine = styled(BaseRow)`
  justify-content: center;
  align-items: center;
`;

const Process = styled.div`
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  background: ${(props) => (props.active ? colorBlue : '#eee')};
  color: ${(props) => (props.active ? '#fff' : '#BDBDBD')};
`;

const ProcessLine = styled.div`
  width: 30px;
  height: 1px;
  background: #eee;
`;

const StartModal3 = memo(
  ({ info, open, baseCoin, startNum, time, startFn, closeFn }) => {
    const [curIndex, setCurIndex] = useState(0);
    const [openWarn, setOpenWarn] = useState(false);
    const [amount, setAmount] = useState('0');
    const [allowStart, setAllowStart] = useState(false);
    const [showLoading, setLoading] = useState(false);

    const { supportedTokens, start, collateral, approve } = useStreamTx();
    const collateralTokenList = useMemo(() => {
      return supportedTokens.filter((v) => {
        return v?.symbol === 'WASP' || v.symbol === 'WAND';
      });
    }, [supportedTokens]);

    const checkBalanceFn = useCallback(
      (token) => {
        const item = supportedTokens.find((v) => v?.address === token);
        if (!item) return 0;
        return item.balance?.toString();
      },
      [supportedTokens]
    );

    useEffect(() => {
      setCurIndex(0);
    }, [open]);

    useEffect(() => {
      setAllowStart(false);
      setAmount('--');
    }, [open, curIndex]);

    useEffect(() => {
      if (!open) return;
      const getAmountFn = async () => {
        const collAmount = await collateral(info, startNum, time, curIndex);
        setAmount(commafy(collAmount));
        const warn = new BigNumber(collAmount).gt(
          checkBalanceFn(collateralTokenList[curIndex]?.address)
        );
        setAllowStart(!warn);
        setOpenWarn(warn);
      };
      getAmountFn();
    }, [
      checkBalanceFn,
      collateral,
      collateralTokenList,
      curIndex,
      info,
      open,
      startNum,
      time,
    ]);

    return (
      <BaseModal title={baseCoin} open={open} closeFn={closeFn}>
        <StartTotal>
          <StartContent>
            <Txt>
              You must stake a certain amount of WASP or WAND to enable the
              stream trade. Note that your collateral will be slashed when your
              deposited {baseCoin?.split('->')[0]} decreases to 0 but the trade
              still goes on.
            </Txt>
            {/* <Txt>Stake amount of {collateralTokenList[curIndex].symbol} as collateral</Txt> */}
            <WalletContext.Consumer>
              {(wallet) => (
                <BtnGroup>
                  {collateralTokenList.map((v, k) => (
                    <CoinBtn
                      active={curIndex === k}
                      key={k}
                      onClick={() => {
                        if (showLoading) return;
                        setCurIndex(k);
                      }}
                    >
                      <SingleCoinIcon
                        url={getCoinUrlByNameFn(v.symbol, wallet.networkId)}
                        margin={'0 8px 0 0'}
                        size={isMobile ? 1.25 : 26}
                      />
                      <div>{v.symbol}</div>
                    </CoinBtn>
                  ))}
                </BtnGroup>
              )}
            </WalletContext.Consumer>
            <InpBorder style={{ marginBottom: '14px', marginTop: '14px' }}>
              <CoinInp>{amount}</CoinInp>
              <InpRight>{collateralTokenList[curIndex]?.symbol}</InpRight>
            </InpBorder>
            <RowBetween style={{ marginBottom: '20px' }}>
              <Txt>Balance</Txt>
              <Txt>
                {commafy(
                  checkBalanceFn(collateralTokenList[curIndex]?.address)
                )}
                &nbsp;{collateralTokenList[curIndex]?.symbol}
              </Txt>
            </RowBetween>
            <BtnContentGroup>
              <BuyBtn
                onClick={() => {
                  const path = curIndex
                    ? '0x230f0C01b8e2c027459781E6a56dA7e1876EFDbe'
                    : '0x8b9f9f4aa70b1b0d586be8adfb19c1ac38e05e9a';
                  window.open(
                    `https://www.wanswap.finance/#/swap?outputCurrency=${path}`
                  );
                }}
              >
                Buy&nbsp;{collateralTokenList[curIndex]?.symbol}
              </BuyBtn>
              {collateralTokenList[curIndex]?.approved ? (
                <Btn
                  disabled={!allowStart || showLoading}
                  style={{ marginBottom: '20px' }}
                  onClick={async () => {
                    if (!allowStart || showLoading) return;
                    setLoading(true);
                    console.log('loading start');
                    try {
                      await start(info, startNum, time, curIndex);
                    } catch (addError) {
                      // handle "add" error
                      console.error('addError', addError);
                      setLoading(false);
                      return;
                    }
                    console.log('loading end');
                    setLoading(false);
                    startFn();
                  }}
                >
                  <Loading size={28} show={showLoading} />
                  Start
                </Btn>
              ) : (
                <Btn
                  disabled={showLoading}
                  style={{ marginBottom: '20px' }}
                  onClick={async () => {
                    if (showLoading) return;
                    setLoading(true);
                    try {
                      await approve(collateralTokenList[curIndex]?.address);
                    } catch (addError) {
                      // handle "add" error
                      console.error('addError', addError);
                    }
                    setLoading(false);
                  }}
                >
                  <Loading size={28} show={showLoading} />
                  Approve
                </Btn>
              )}
            </BtnContentGroup>
          </StartContent>
          <TimeLine>
            <Process>1</Process>
            <ProcessLine />
            <Process>2</Process>
            <ProcessLine />
            <Process active={true}>3</Process>
          </TimeLine>
          {openWarn && (
            <Warn>
              The amount of your {collateralTokenList[curIndex]?.symbol} is
              insufficent for collateral
            </Warn>
          )}
        </StartTotal>
      </BaseModal>
    );
  }
);

export default StartModal3;
