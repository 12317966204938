import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import search from '../../static/search.png';
import { useMedia } from 'react-use';

const colorBlue = '#5B86E5';
const TitleCon = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px 40px;
`;
const ThItem = styled.div`
  display: flex;
  flex: ${(props) => props.fx};
  width: ${(props) => props.w};
  padding: ${(props) => (props.pd ? props.pd : '0 18px 0 0')};
  margin: ${(props) => (props.mr ? props.mr : '0px')};
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;

  ${({ theme }) => theme.mediaWidth.upToNorLarge`
		font-size: 15px;
	`}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		font-size: 14px;
	`}

  ${({ theme }) => theme.mediaWidth.upToMedium`
		font-size: 13px;
	`}
`;
const FixWidthCon = styled(ThItem)`
  width: 172px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
		font-size: 14px;
	`}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		font-size: 12px;
		width: 140px;
		flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
	`}

  ${({ theme }) => theme.mediaWidth.upToMedium`
		width: 80px;
	`}
`;
const SearchCon = styled(FixWidthCon)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 0 8px;
	`}
`;
const Inp = styled.input`
  border: none;
  width: 70%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.2);
  background: none;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		font-size: 12px;
	`}
`;
const SearchIcon = styled.span`
  width: 18px;
  height: 18px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-size: contain;
`;

const Title = memo((props) => {
  const bellow1100 = useMedia('(max-width: 1100px)');
  const { searchInp, changeValueFn } = props;
  const { t } = useTranslation();
  const setInpFn = (e) => {
    changeValueFn(e?.target?.value);
  };
  return (
    <TitleCon>
      <ThItem color={colorBlue} fx={2}>
        {t('Row.type')}
      </ThItem>
      <ThItem color={colorBlue} fx={2}>
        {t('Row.inper')}
      </ThItem>
      <ThItem color={colorBlue} fx={2}>
        {t('Row.resper')}
      </ThItem>
      <FixWidthCon pd={'0px'} mr={'0 18px 0 0'}></FixWidthCon>
      <ThItem fx={11} pd={'0px'}>
        <ThItem
          color={colorBlue}
          fx={!bellow1100 ? 4 : 'none'}
          w={!bellow1100 ? 'auto' : '150px'}
          pd={!bellow1100 ? '' : '0px'}
          mr={!bellow1100 ? '' : '0 18px 0 0'}
        >
          {t('Row.direction')}
        </ThItem>
        <ThItem color={colorBlue} fx={2}>
          {t('Row.time')}
        </ThItem>
        {/* <ThItemTh color={colorBlue}>{t('Row.restime')}</ThItemTh> */}
        <ThItem color={colorBlue} fx={2}>
          {t('Row.useper')}
        </ThItem>
        <ThItem pd={'0px'} mr={'0px'}>
          <SearchCon>
            <Inp value={searchInp} onChange={setInpFn} placeholder='Search' />
            <SearchIcon />
          </SearchCon>
        </ThItem>
      </ThItem>
    </TitleCon>
  );
});

export default Title;
