import React, {
  useCallback,
  useEffect,
  useState,
  memo,
  useMemo,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';
import BaseModal from '../Modal/Base';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { commafy, getT2DFn, getCoinUrlByNameFn } from '../../utils';
import SingleCoinIcon from '../Icon/Single';
import { WalletContext } from '../../utils/wallet';
import { ChevronDown, Search, XCircle } from 'react-feather';
import right from '../../static/right.png';

const baseHeight = 50;
const contentHeight = 510;
const HistoryModalCon = styled.div`
  width: 100%;
  padding: 0 32px;
  border-top: 1px solid #eeeeee;
  margin-top: -10px;
  padding-top: 20px;
  ${isMobile &&
  css`
    padding: 0 1.25rem;
    margin-bottom: 1.25rem;
    border: none;
  `}
`;
const BtnGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  // margin-bottom: 20px;
`;
const TagBtn = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  margin-right: 12px;
  padding: 0 12px;
  margin-bottom: 20px;
  background-color: ${(props) =>
    props.active ? 'rgba(91, 134, 229, 0.1)' : ''};
  cursor: pointer;
`;
const Title = styled.div`
  width: 100%;
  display: flex;
  background: #f6f6f6;
  border-radius: 8px;
`;
const Tag = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${(props) =>
    props.direction ? props.direction : 'flex-start'};
  align-items: center;
  height: ${baseHeight}px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #243559;
  padding: 0 24px;
`;
const Content = styled.div`
  min-height: ${baseHeight}px;
  height: ${contentHeight}px;
  max-height: 50vh;
  overflow-y: auto;

  ${isMobile &&
  css`
    margin-top: 1.25rem;
  `}
`;
const HistoryCon = styled.div``;
const HistoryItemLine = styled.div`
  display: flex;
  min-height: ${baseHeight}px;
  background-color: ${(props) => (props.index % 2 === 1 ? '#F6F6F6' : 'none')};
  border-radius: 8px;
`;
const HistoryItemMobileLine = styled.div`
  background-color: ${(props) => (props.index % 2 === 1 ? 'none' : '#F6F6F6')};
  border-radius: 8px;
  padding: 1rem 0.875rem 0.5rem;
`;
const Item = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(props) =>
    props.direction ? props.direction : 'flex-start'};
  overflow-wrap: anywhere;
  font-size: 16px;
  font-weight: 400;
  padding: 0 24px;
`;
const ItemLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  align-items: center;
`;
const ItemName = styled.div`
  display: flex;
  font-size: 14px;
  color: #243559;
  align-items: center;
`;
const ItemCon = styled.div`
  display: flex;
  flex-directiong: flex-end;
  flex-wrap: wrap;
  align-items: center;
`;
const ItemPer = styled.span`
  font-size: 12px;
  color: #8f8f8f;
`;
const LoadingCon = styled.div`
  display: flex;
  min-height: ${baseHeight}px;
  justify-content: center;
  align-items: center;
`;
const InpContent = styled.div`
  height: ${baseHeight}px;
  position: relative;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  ${(props) =>
    props.isSelect &&
    css`
      border-color: #5b86e5;
      background: #eff3fd;
    `}
`;
const SelectContent = styled.div`
  padding: 0 12px;
  height: 100%;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;
const SelectedCon = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NoSelectedCon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const PlaceholderTxt = styled.p`
  color: #b5b5b5;
  font-size: 16px;
`;
const InpCon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 100%;
`;
const Inp = styled.input`
  width: 0;
  flex: 1;
  margin-right: 10px;
  font-size: 16px;
  color: #5b86e5;
  height: 100%;
`;
const PairsGroup = styled.div`
  width: 100%;
  height: ${contentHeight}px;
  max-height: 50vh;
  position: absolute;
  top: ${baseHeight + 10}px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 20px 12px;
  z-index: 1;
`;
const PairsScrollCon = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;
const PairsGroupItem = styled.div`
  display: flex;
  height: 60px;
  font-size: 16px;
  align-items: center;
  border-radius: 8px;
`;
const PairsGroupItemHover = styled.div`
  display: flex;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;

  :hover {
    border-color: #5b86e5;
    background: #eff3fd;
  }
  ${(props) =>
    props.isChoose &&
    css`
      border-color: #5b86e5;
      background: #eff3fd;
    `}
`;
const InpShowCon = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const FilterInp = memo((props) => {
  const {
    pairs,
    wallet,
    mobileDefaultTradeAddress,
    mobileChange,
    updateMobileDefaultFn,
    getHistoryFn,
    isChooseFixPair,
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const { t } = useTranslation();
  const [inpValue, setInpValue] = useState('');
  const [curPairItem, setCurPairItem] = useState({});
  const inpFn = (e) => {
    setInpValue(e?.target?.value);
  };
  const ref = useRef();
  const curPairs = useMemo(() => {
    if (!inpValue) return pairs;
    let arr = pairs.filter((v) => {
      const reg = new RegExp(inpValue.toLocaleLowerCase());
      const nameArr = v.name.split('->');
      const fromName = nameArr[0].toLocaleLowerCase();
      const toName = nameArr[nameArr.length - 1].toLocaleLowerCase();
      const checkToken = reg.test(fromName) || reg.test(toName);
      return checkToken;
    });
    return arr;
  }, [inpValue, pairs]);
  useEffect(() => {
    const fn = () => {
      setIsFocus(false);
    };
    if (isFocus) {
      console.log(ref);
      ref.current.focus();
    } else {
      ref.current.blur();
    }
    const dom = document.getElementById('modal');
    if (!dom) return;
    dom.addEventListener('mouseup', fn);
    return () => {
      dom.removeEventListener('mouseup', fn);
    };
  }, [isFocus]);
  useEffect(() => {
    if (!mobileDefaultTradeAddress || !updateMobileDefaultFn) return;
    if (mobileChange) return;
    const item = pairs.find(
      (v) => v.tradeAddress === mobileDefaultTradeAddress
    );
    setIsSelect(true);
    setCurPairItem(item);
    updateMobileDefaultFn();
  }, [mobileChange, mobileDefaultTradeAddress, pairs, updateMobileDefaultFn]);
  useEffect(() => {
    if (!isChooseFixPair) return;
    setIsFocus(false);
    setIsSelect(false);
    setInpValue('');
  }, [isChooseFixPair]);
  return (
    <InpContent isSelect={!isFocus && isSelect}>
      <InpShowCon show={isFocus}>
        {/* {
          isFocus ?
            <InpCon>
              <Inp ref={ref} value={inpValue} onChange={inpFn} autoFocus />
              <Search color="#5b86e5" size={26} />
            </InpCon>
          :
            null
        } */}
        <InpCon>
          <Inp
            ref={ref}
            value={inpValue}
            onChange={inpFn}
            autoFocus
            onClick={(e) => {
              e.preventDefault();
              ref.current.focus();
              setIsFocus(true);
            }}
          />
          <Search color='#5b86e5' size={26} />
        </InpCon>
        <PairsGroup>
          <PairsScrollCon>
            {curPairs.map((v, k) => (
              <PairsGroupItemHover
                key={k}
                onClick={(e) => {
                  e.stopPropagation();
                  getHistoryFn(v.tradeAddress, v.name);
                  setIsSelect(true);
                  setCurPairItem(v);
                  setIsFocus(false);
                  updateMobileDefaultFn && updateMobileDefaultFn();
                }}
              >
                {getNamePairsFn(v.name).map((item, index) => (
                  <PairsGroupItem key={index}>
                    {index ? (
                      <img
                        src={right}
                        style={{
                          width: '20px',
                          marginLeft: '10px',
                        }}
                        alt=''
                      />
                    ) : null}
                    <SingleCoinIcon
                      url={getCoinUrlByNameFn(item, wallet.networkId)}
                      size={isMobile ? 2.25 : 36}
                      margin={'0 10px'}
                    />
                    {item}
                  </PairsGroupItem>
                ))}
              </PairsGroupItemHover>
            ))}
          </PairsScrollCon>
        </PairsGroup>
      </InpShowCon>

      <SelectContent
        show={!isFocus}
        onClick={() => {
          setIsFocus(true);
        }}
      >
        {isSelect ? (
          <SelectedCon>
            <TagBtn style={{ padding: 0, margin: 0, border: 'none' }}>
              {getNamePairsFn(curPairItem?.name).map((item, index) => (
                <PairsGroupItem
                  key={index}
                  style={{ height: baseHeight + 'px' }}
                >
                  {index ? (
                    <img
                      alt=''
                      src={right}
                      style={{
                        width: '20px',
                        marginLeft: '4px',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <SingleCoinIcon
                    url={getCoinUrlByNameFn(item, wallet.networkId)}
                    size={isMobile ? 1.25 : 20}
                    margin={'0 4px'}
                  />
                  {item}
                </PairsGroupItem>
              ))}
            </TagBtn>
            <XCircle
              onClick={() => {
                setIsSelect(false);
                setInpValue('');
              }}
              color='#5B86E5'
              size={18}
            />
          </SelectedCon>
        ) : (
          <NoSelectedCon>
            <PlaceholderTxt>{t('Choose direction')}</PlaceholderTxt>
            <ChevronDown color='#5b86e5' size={26}></ChevronDown>
          </NoSelectedCon>
        )}
      </SelectContent>
    </InpContent>
  );
});

const getNamePairsFn = (name) => {
  const arr = name.split('->');
  return [arr[0], arr[arr.length - 1]];
};
function HistoryItem(props) {
  const { item, index } = props;

  return (
    <HistoryItemLine index={index}>
      <Item>{getT2DFn(item.startTime)}</Item>
      <Item>
        {commafy(item.sellAmount)}&nbsp;
        <ItemPer>{item.sellToken}</ItemPer>
      </Item>
      <Item>
        {commafy(item.buyAmount)}&nbsp;
        <ItemPer>{item.buyToken}</ItemPer>
      </Item>
      <Item direction={'flex-end'}>{commafy(item.price)}</Item>
    </HistoryItemLine>
  );
}
function HistoryItemMobile(props) {
  const { item, index, wallet, fromName, toName, columns } = props;

  return (
    <HistoryItemMobileLine index={index}>
      <ItemLine>
        <ItemName>{columns[0].title}</ItemName>
        <ItemCon>{getT2DFn(item.startTime)}</ItemCon>
      </ItemLine>
      <ItemLine>
        <ItemName>{columns[1].title}</ItemName>
        <ItemCon>
          {commafy(item.sellAmount)}&nbsp;
          <ItemPer>{item.sellToken}</ItemPer>
        </ItemCon>
      </ItemLine>
      <ItemLine>
        <ItemName>{columns[2].title}</ItemName>
        <ItemCon>
          {commafy(item.buyAmount)}&nbsp;
          <ItemPer>{item.buyToken}</ItemPer>
        </ItemCon>
      </ItemLine>
      <ItemLine>
        <ItemName>
          {columns[3].title}&nbsp;(
          <SingleCoinIcon
            url={getCoinUrlByNameFn(fromName, wallet.networkId)}
            size={0.875}
            margin={'0 4px'}
          />
          /
          <SingleCoinIcon
            url={getCoinUrlByNameFn(toName, wallet.networkId)}
            size={0.875}
            margin={'0 4px'}
          />
          )
        </ItemName>
        <ItemCon>{commafy(item.price)}</ItemCon>
      </ItemLine>
    </HistoryItemMobileLine>
  );
}
function HistoryContent(props) {
  const { wallet, showModal } = props;
  const { t } = useTranslation();
  const { supportedPairs, getHistory } = useStreamTx();
  // console.log('dfdfd', supportedPairs)
  const columns = [
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'Sell',
      dataIndex: 'sell',
      key: 'sell',
    },
    {
      title: 'Buy',
      dataIndex: 'buy',
      key: 'buy',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [curTradeAddress, setCurTradeAddress] = useState('');
  const [fromName, setFromName] = useState('');
  const [toName, setToName] = useState('');
  const [isChooseFixPair, setIsChooseFixPair] = useState(false);
  const defaultPairArr = useMemo(() => {
    const pairArr = {
      888: [
        {
          fromToken: '0x0000000000000000000000000000000000000000',
          name: 'WAN->wanUSDT',
          toToken: '0x11e77E27Af5539872efEd10abaA0b408cfd9fBBD',
          tradeAddress: '0x61e7a66804E85B4b09326bE738D2f98A6445C2C6',
        },
        {
          fromToken: '0x11e77E27Af5539872efEd10abaA0b408cfd9fBBD',
          name: 'wanUSDT->WAN',
          toToken: '0x0000000000000000000000000000000000000000',
          tradeAddress: '0x5e8FE39A61CE98b5Ad80Fa9F2d2B631E89ef1a0f',
        },
        {
          fromToken: '0x6e11655d6aB3781C6613db8CB1Bc3deE9a7e111F',
          name: 'ZOO->WASP->wanUSDT',
          toToken: '0x11e77E27Af5539872efEd10abaA0b408cfd9fBBD',
          tradeAddress: '0x3b8B84e46daA8B7dE78B61921ACF3410a48E5c86',
        },
      ],
      999: [
        {
          fromToken: '0x0000000000000000000000000000000000000000',
          name: 'WAN->wanUSDT',
          toToken: '0x0f6be49eB9d86f97dE0EB759c856bFb0db8316f7',
          tradeAddress: '0x7Cb6f0628528f05Cfa09A4AeDb5530c2f1A1F453',
        },
        {
          fromToken: '0x0f6be49eB9d86f97dE0EB759c856bFb0db8316f7',
          name: 'wanUSDT->WAN',
          toToken: '0x0000000000000000000000000000000000000000',
          tradeAddress: '0xe67167A0EEaC4A5Abc2AC94CFb136792288dc648',
        },
      ],
    };
    return pairArr[wallet.networkId];
  }, [wallet.networkId]);
  const [mobileDefaultTradeAddress, setMobileDefaultTradeAddress] =
    useState('');
  const [mobileChange, setMobileChange] = useState(0);
  const getHistoryFn = useCallback(
    async (tradeAddress, name) => {
      if (!tradeAddress) return;
      setCurTradeAddress(tradeAddress);
      const nameArr = getNamePairsFn(name);
      setFromName(nameArr[0]);
      setToName(nameArr[nameArr.length - 1]);
      setLoading(true);
      let res = await getHistory(tradeAddress);
      console.log(res);
      res && setData(res);
      setLoading(false);
    },
    [getHistory]
  );
  const updateMobileDefaultFn = () => {
    setMobileDefaultTradeAddress('');
    setMobileChange(mobileChange + 1);
  };
  useEffect(() => {
    if (showModal && !curTradeAddress && !isMobile) {
      const item = supportedPairs[0];
      getHistoryFn(item.tradeAddress, item.name);
      setCurTradeAddress(item.tradeAddress);
      setIsChooseFixPair(true);
    }
  }, [curTradeAddress, getHistoryFn, showModal, supportedPairs]);
  useEffect(() => {
    if (!showModal || !isMobile || mobileChange) return;
    const item = supportedPairs[0];
    console.log(!mobileChange);
    isMobile &&
      !mobileChange &&
      setMobileDefaultTradeAddress(item.tradeAddress);
    getHistoryFn(item.tradeAddress, item.name);
    setCurTradeAddress(item.tradeAddress);
    setIsChooseFixPair(true);
  }, [getHistoryFn, mobileChange, showModal, supportedPairs]);
  useEffect(() => {
    if (showModal) return;
    setMobileChange(0);
  }, [showModal]);
  return (
    <HistoryModalCon>
      {isMobile ? (
        <FilterInp
          mobileDefaultTradeAddress={mobileDefaultTradeAddress}
          wallet={wallet}
          pairs={supportedPairs}
          mobileChange={mobileChange}
          getHistoryFn={getHistoryFn}
          updateMobileDefaultFn={updateMobileDefaultFn}
        ></FilterInp>
      ) : (
        <BtnGroup>
          {defaultPairArr.map((v, k) => {
            return (
              <TagBtn
                key={v.name}
                active={v.tradeAddress === curTradeAddress}
                onClick={() => {
                  getHistoryFn(v.tradeAddress, v.name);
                  setIsChooseFixPair(true);
                }}
              >
                {getNamePairsFn(v.name).map((item, index) => (
                  <div style={{ display: 'flex' }} key={index}>
                    {index ? (
                      <img
                        alt=''
                        src={right}
                        style={{
                          width: '20px',
                          marginLeft: '4px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <SingleCoinIcon
                      url={getCoinUrlByNameFn(item, wallet.networkId)}
                      size={20}
                      margin={'0 4px'}
                    />
                    {item}
                  </div>
                ))}
              </TagBtn>
            );
          })}
          <FilterInp
            isChooseFixPair={isChooseFixPair}
            wallet={wallet}
            pairs={supportedPairs}
            getHistoryFn={(add, name) => {
              getHistoryFn(add, name);
              setIsChooseFixPair(false);
            }}
          ></FilterInp>
        </BtnGroup>
      )}
      {!isMobile && (
        <Title>
          {columns.map((item, index) => (
            <Tag
              key={index}
              direction={index === columns.length - 1 ? 'flex-end' : ''}
            >
              {item.title}
              {index === columns.length - 1 ? (
                <>
                  <SingleCoinIcon
                    url={getCoinUrlByNameFn(fromName, wallet.networkId)}
                    size={14}
                    margin={'0 4px'}
                  />
                  /
                  <SingleCoinIcon
                    url={getCoinUrlByNameFn(toName, wallet.networkId)}
                    size={14}
                    margin={'0 4px'}
                  />
                </>
              ) : null}
            </Tag>
          ))}
        </Title>
      )}
      <Content>
        {loading ? (
          <LoadingCon>
            <Loading
              size={20}
              mr={'0px'}
              show={loading}
              color={
                'rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.5)'
              }
            />
          </LoadingCon>
        ) : (
          <HistoryCon>
            {data.length ? (
              data.map((item, index) =>
                isMobile ? (
                  <HistoryItemMobile
                    key={index}
                    item={item}
                    index={index}
                    wallet={wallet}
                    fromName={fromName}
                    toName={toName}
                    noBorder={index === data.length - 1}
                    columns={columns}
                  ></HistoryItemMobile>
                ) : (
                  <HistoryItem
                    key={index}
                    item={item}
                    index={index}
                    noBorder={index === data.length - 1}
                  ></HistoryItem>
                )
              )
            ) : (
              <LoadingCon>{t('No Data')}</LoadingCon>
            )}
          </HistoryCon>
        )}
      </Content>
    </HistoryModalCon>
  );
}

const HistoryModal = memo((props) => {
  const { showModal, hideModalFn } = props;
  const { t } = useTranslation();

  return (
    <BaseModal
      title={t('History')}
      closeFn={hideModalFn}
      open={showModal}
      width={'1000px'}
    >
      <WalletContext.Consumer>
        {(wallet) => (
          <HistoryContent
            showModal={showModal}
            wallet={wallet}
          ></HistoryContent>
        )}
      </WalletContext.Consumer>
    </BaseModal>
  );
});

export default HistoryModal;
