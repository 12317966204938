import React, { useState } from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-use';
import TimeLeft from './TimeLeft';
import PaidContent from './Paid';
import { SquareBtn } from '../Button';
import { ChevronDown } from 'react-feather';
import NameDirection from './NameDirection';
import StartBtn from './StartBtn';
import StopBtn from './StopBtn';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { getCoinUrlByAddressFn } from '../../utils';
import { WalletContext } from '../../utils/wallet';

const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';
const colorBlue = '#5B86E5';

const TradeChildItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`;
const Item = styled.div`
  display: flex;
  flex: ${(props) => props.fx};
  margin: ${(props) => (props.mr ? props.mr : '0 18px 0 0')};
  padding: ${(props) => (props.pd ? props.pd : '0px')};
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  width: ${(props) => props.w};
`;
const DirectionItem = styled(Item)`
  position: relative;
  cursor: pointer;
`;
const FixWidthCon = styled(Item)`
  width: 172px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
		font-size: 14px;
	`}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		font-size: 12px;
		width: 140px;
		flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: ${(props) => (props.reverse ? 'center' : 'flex-start')};
	`}

  ${({ theme }) => theme.mediaWidth.upToMedium`
		width: 80px;
	`}
`;
const UnfoldBtn = styled(SquareBtn)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  // border: 1px solid ${colorBlue};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: ${(props) => (props.hasBlock ? 'visible' : 'hidden')};

  & .arrowIcon {
    // transition: all 0.2s ease-out;
    transform: rotate(${(props) => (props.unfold ? '-180deg' : '0deg')});
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 20px;
    height: 20px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 16px;
    height: 16px;
  `}
`;

const TradeChild = (props) => {
  const {
    unfold,
    index,
    hasBlock,
    info,
    item,
    setCurIndexFn,
    setStartIndexFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    setOpenStartModal1Fn,
  } = props;
  const { stop } = useStreamTx();
  const bellow1100 = useMedia('(max-width: 1100px)');
  const [stoping, setStoping] = useState(false);
  const pathFn = (wallet, info) => {
    window.open(
      `https://${
        wallet.networkId === 999 ? 'testnet.' : ''
      }wanscan.org/address/${info.tradeAddress}`
    );
  };
  return (
    <WalletContext.Consumer>
      {(wallet) => (
        <TradeChildItem>
          <DirectionItem
            fx={!bellow1100 ? 4 : 'none'}
            w={!bellow1100 ? 'auto' : '150px'}
          >
            {!bellow1100 && (
              <NameDirection
                src={[
                  getCoinUrlByAddressFn(info.fromToken, wallet.networkId),
                  getCoinUrlByAddressFn(info.toToken, wallet.networkId),
                ]}
                pathFn={() => pathFn(wallet, info)}
                info={info}
                item={item}
              ></NameDirection>
            )}
            <UnfoldBtn
              unfold={unfold}
              onClick={() => setCurIndexFn(unfold ? '-1' : index)}
              hasBlock={hasBlock}
            >
              <ChevronDown
                size={20}
                color={'rgba(255, 255, 255, 0.5)'}
                className='arrowIcon'
              />
            </UnfoldBtn>
          </DirectionItem>
          <Item fx={2} color={colorFFFOpacity3}>
            <TimeLeft tradeAddress={info.tradeAddress}></TimeLeft>
          </Item>
          <Item fx={2}>
            <PaidContent
              tradeAddress={info.tradeAddress}
              index={index}
              curItem={item}
            ></PaidContent>
          </Item>

          <FixWidthCon pd={'0px'} mr={'0px'} reverse={true}>
            <StartBtn
              info={info}
              index={index}
              stoping={stoping}
              setStartIndexFn={setStartIndexFn}
              setStartInfoFn={setStartInfoFn}
              setStartNumFn={setStartNumFn}
              setTimeFn={setTimeFn}
              setOpenStartModal1Fn={setOpenStartModal1Fn}
              setStopingFn={() => setStoping(false)}
            ></StartBtn>
            <StopBtn
              info={info}
              stop={stop}
              setStopingFn={(i) => setStoping(i)}
            ></StopBtn>
          </FixWidthCon>
        </TradeChildItem>
      )}
    </WalletContext.Consumer>
  );
};

export default TradeChild;
