import React, { memo } from 'react';
import MobileTradeItem from './MobileTradeItem';
import styled from 'styled-components';

const ListCon = styled.div`
  display: flex;
  flex-direction: column;
`;

const TradeList = memo((props) => {
  const {
    tokenList,
    curIndex,
    setCurIndexFn,
    setStartIndexFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    chooseDepositFn,
    chooseWithdrawFn,
    setOpenWithdrawModalFn,
    setOpenDepositsModalFn,
    setOpenStartModal1Fn,
  } = props;
  return (
    <ListCon>
      {tokenList.map((item, index) => (
        <MobileTradeItem
          key={item.address}
          item={item}
          unfold={index === curIndex}
          index={index}
          setCurIndexFn={(i) => setCurIndexFn(i)}
          setStartIndexFn={(i) => setStartIndexFn(i)}
          setStartInfoFn={(i) => setStartInfoFn(i)}
          setStartNumFn={(i) => setStartNumFn(i)}
          setTimeFn={(i) => setTimeFn(i)}
          chooseDepositFn={(i) => chooseDepositFn(i)}
          chooseWithdrawFn={(i) => chooseWithdrawFn(i)}
          setOpenDepositsModalFn={(i) => setOpenDepositsModalFn(i)}
          setOpenWithdrawModalFn={(i) => setOpenWithdrawModalFn(i)}
          setOpenStartModal1Fn={(i) => setOpenStartModal1Fn(i)}
        ></MobileTradeItem>
      ))}
    </ListCon>
  );
});

export default TradeList;
