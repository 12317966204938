import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import HistoryBtn from '../../components/History/HistoryBtn';
import HistoryModal from '../../components/History/HistoryModal';

const HistoryBtnLine = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 16px;
  ${isMobile &&
  css`
    margin-right: 0;
  `}
`;

function History() {
  const [showModal, setShowModal] = useState(false);
  const showModalFn = () => {
    setShowModal(true);
  };
  const hideModalFn = () => {
    setShowModal(false);
  };
  return (
    <>
      <HistoryBtnLine>
        <HistoryBtn showModalFn={showModalFn}></HistoryBtn>
      </HistoryBtnLine>
      {showModal && (
        <HistoryModal
          showModal={showModal}
          hideModalFn={hideModalFn}
        ></HistoryModal>
      )}
    </>
  );
}

export default History;
