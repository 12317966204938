import coinConfig, { defaultItem } from '../coin';
import { getAddress } from '@ethersproject/address';

export const dbFn = (num) => {
  return num > 9 ? num : `0${num}`;
};
export const getTimeFn = (time) => {
  if (!+time || +time !== Number(time)) return '-';
  const second = parseInt(time % 60);
  let per = 60;
  if (time / per < 1) return `${second}s`;
  let curTime = second;
  const mPer = 60;
  per *= 60;
  const min = parseInt(((time % per) - curTime) / mPer);
  if (time / per < 1) return `${min}m ${second}s`;
  curTime += min * mPer;
  const hPer = mPer * 60;
  per *= 24;
  const hour = parseInt(((time % per) - curTime) / hPer);
  if (time / per < 1) return `${hour}h ${min}m ${second}s`;
  curTime += hour * hPer;
  const dPer = hPer * 24;
  per *= 30;
  const day = parseInt(((time % per) - curTime) / dPer);
  if (time / per < 1) return `${day}d ${hour}h ${min}m ${second}s`;
  curTime += day * dPer;
  const monPer = dPer * 30;
  per = dPer * 365;
  const month = parseInt(((time % per) - curTime) / monPer);
  if (time / per < 1) return `${month}m ${day}d ${hour}h ${min}m ${second}s`;
  const year = parseInt(time / per);
  return `${year}y ${month}m ${day}d ${hour}h ${min}m ${second}s`;
};
export const getT2DFn = (time) => {
  var date = new Date(Number(time));
  const Y = date.getFullYear() + '-';
  const M = dbFn(date.getMonth() + 1) + '-';
  const D = dbFn(date.getDate()) + ' ';
  const h = dbFn(date.getHours()) + ':';
  const m = dbFn(date.getMinutes()) + ':';
  const s = dbFn(date.getSeconds());
  return Y + M + D + h + m + s;
};

export const isSupportedNetwork = (chainId) => {
  return [888, 999].includes(Number(chainId));
};

export const getCoinUrlByNameFn = (name, chainId) => {
  if (!isSupportedNetwork(chainId)) {
    chainId = 888;
  }
  const item = coinConfig[chainId].find(
    (v) => v.name.toLocaleLowerCase() === name.toLocaleLowerCase()
  );
  if (!item) return defaultItem.logoURI;
  return item.logoURI;
};
export const getCoinUrlByAddressFn = (address, chainId) => {
  if (!isSupportedNetwork(chainId)) {
    chainId = 888;
  }
  const item = coinConfig[chainId].find(
    (v) => v.address.toLowerCase() === address.toLocaleLowerCase()
  );
  if (!item) return defaultItem.logoURI;
  return item.logoURI;
};
export const isAddress = (value) => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
};
export const shortenAddress = (address, chars = 4) => {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
};
export const commafy = (num) => {
  if (num === 0 || (num && num.toString() === 0)) {
    return '0';
  }

  if (!num || isNaN(num)) {
    return '--';
  }

  num = num.toString();

  if (!num.includes('.')) {
    num += '.0';
  } else {
    if (num.indexOf('.') > 3) {
      num = Number(num).toFixed(1);
    } else if (num.length > 5) {
      num = Number(num).toFixed(4);
    }
  }

  return num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    return $1 + ',';
  });
};
export const getItemFn = (arr, name, key) => {
  if (!Array.isArray(arr) || !name || !key) return {};
  const item = arr.find((v) => v[name] === key);
  return item === void 1 ? {} : item;
};

const utils = {
  dbFn,
  getTimeFn,
  getCoinUrlByNameFn,
  commafy,
  getItemFn,
  getT2DFn,
};

export default utils;
