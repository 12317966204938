import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import right from '../../static/right.png';
import { isMobile } from 'react-device-detect';
import TradeIntroduce from './TradeIntroduce';
import SingleCoinIcon from '../Icon/Single';

const NameGroup = styled.div`
  display: flex;
  min-width: 240px;
  margin-left: 10px;
  flex-wrap: wrap;
  position: relative;

  &:hover .introduce {
    display: block;
  }

  ${({ theme }) => theme.mediaWidth.upToMediumLarge`
      flex: 1;
      min-width: unset;
    `}

  ${isMobile &&
  css`
    margin-left: 0;
  `}
`;
const NameDirectionCon = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const TradeCon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const Txt = styled.p`
  color: ${(props) => (props.color ? props.color : '#fff')};
  padding: ${(props) => (props.pd ? props.pd : '0')};
  font-weight: ${(props) => (props.fw ? props.fw : 'normal')};
  font-size: 16px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    font-size: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 14px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
  `}

  ${isMobile &&
  css`
    font-size: 0.875rem !important;
    align-items: center;
  `}
`;

const NameDirection = memo((props) => {
  const { info, item, pathFn, src } = props;

  const getNameArr = useMemo(
    (name) => {
      const arr = info.name.split('->');
      const len = arr.length - 1;
      return [arr[0], arr[len]];
    },
    [info.name]
  );

  return (
    <NameGroup>
      {getNameArr.map((value, key) => (
        <NameDirectionCon key={key}>
          {key !== 0 && (
            <TradeCon style={{ margin: '0 4px' }}>
              <img
                src={right}
                style={{ width: '20px', cursor: 'pointer' }}
                onClick={pathFn}
                alt=''
              />
            </TradeCon>
          )}
          <SingleCoinIcon size={isMobile ? 2 : 20} url={src[key]} />
          <Txt onClick={pathFn}>{value}</Txt>
        </NameDirectionCon>
      ))}
      {!isMobile && <TradeIntroduce item={item} info={info}></TradeIntroduce>}
    </NameGroup>
  );
});

export default NameDirection;
