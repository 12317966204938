import { useEffect, useRef } from 'react';
import { CircleBtn } from '../../components/Button';
import { shortenAddress } from '../../utils/index';
import styled, { css } from 'styled-components';
import walletIcon from '../../static/wallet.png';
import { isMobile } from 'react-device-detect';
import Jazzicon from 'jazzicon';

const Txt = styled.span`
  display: flex;
  align-items: center;
`;

const WalletCon = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const FooterCircleBtn = styled(CircleBtn)`
  ${isMobile &&
  css`
    font-size: 12px;
  `}
`;
const WalletCircleBtn = styled(FooterCircleBtn)`
  background: rgba(255, 255, 255, 0.05);
  height: 44px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #fff;
`;
const WalletCircleWrongBtn = styled(WalletCircleBtn)`
  background: rgba(235, 81, 81, 0.1);
  border: 1px solid rgba(235, 81, 81, 0.5);
  color: #eb5151;
  display: ${(props) => (props.hide ? 'none' : 'flex')};
`;

const StyledIdenticonContainer = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 10px;
  margin-right: 10px;
`;

function Identicon(props) {
  const ref = useRef();
  const { account } = props;

  useEffect(() => {
    ref.current.innerHTML = '';
    const size = 16;
    ref.current.appendChild(
      Jazzicon(size, parseInt(account.slice(2, 10), size))
    );
  }, [account]);
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
  return <StyledIdenticonContainer ref={ref} />;
}

function WalletCom(props) {
  const { wallet, connectWallet } = props;

  const { address, networkId, connected } = wallet;

  return (
    <>
      <WalletCon>
        {connected ? (
          <>
            <WalletCircleBtn
              onClick={connectWallet}
              style={{ marginRight: '16px' }}
            >
              <Identicon account={address}></Identicon>
              <Txt>{shortenAddress(address)}</Txt>
            </WalletCircleBtn>
            <WalletCircleWrongBtn
              hide={+networkId === 888}
              style={{ marginRight: '16px' }}
              onClick={() => {
                wallet.switchNetwork({
                  chainId: '0x378',
                  chainName: 'Wanchain',
                  rpcUrls: ['https://gwan-ssl.wandevs.org:56891'],
                  blockExplorerUrls: ['https://wanscan.org/'],
                  nativeCurrency: {
                    name: 'WAN',
                    symbol: 'WAN', // 2-6 characters long
                    decimals: 18,
                  },
                });
              }}
            >
              {+networkId === 999 ? 'Testnet' : 'Wrong Network'}
            </WalletCircleWrongBtn>
          </>
        ) : (
          <FooterCircleBtn
            onClick={connectWallet}
            style={{ marginRight: '16px' }}
          >
            {
              // error ?
              //   (
              //     <Txt>
              //       Wrong network
              //     </Txt>
              //   )
            }
            <Txt>
              <img
                src={walletIcon}
                style={{ width: '20px', margin: '0 8px 0 0' }}
                alt=''
              />
              <span>Connect Wallet</span>
            </Txt>
          </FooterCircleBtn>
        )}
      </WalletCon>
    </>
  );
}

export default WalletCom;
