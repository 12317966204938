import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingCon = styled.div`
  width: ${(props) => (props.size ? props.size : '48')}px;
  height: ${(props) => (props.size ? props.size : '48')}px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.mr ? props.mr : '0 10px 0 0')};
`;

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const LoadingItem = styled.div`
  width: 96%;
  height: 96%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: ${(props) => props.bdw * 0.2 || '10'}px solid;
  border-color: ${(props) =>
    props.color
      ? props.color
      : 'rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5)'};
  animation: ${rotation} 1s linear infinite;
`;

const Loading = memo((props) => {
  return (
    <LoadingCon {...props}>
      <LoadingItem bdw={props.size} color={props.color}></LoadingItem>
    </LoadingCon>
  );
});

export default Loading;
