import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { SquareBtn } from '../Button';
import { isMobile } from 'react-device-detect';
import Loading from '../Loading';

const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';
const colorRed = '#F05F5F';

const Btn = styled(SquareBtn)`
  border-radius: 8px;
  border: 1px solid;
  width: 80px;
  margin: ${(props) => (props.hasMr ? '0 12px 0 0' : '0')};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin: ${(props) => (props.hasMr ? '0 0 12px 0' : '0')};
    font-size: 12px;
	`}

  ${isMobile &&
  css`
    width: 48%;
    margin: ${(props) => (props.hasMr ? '0 0.5rem 0 0' : '0')};
    font-size: 0.75rem;
  `}
`;

const StopBtn = memo((props) => {
  const { info, stop, setStopingFn } = props;
  const [showLoading, setShowLoading] = useState(false);

  return (
    <Btn
      disabled={!info.isWorking || showLoading}
      color={!info.isWorking || showLoading ? colorFFFOpacity3 : colorRed}
      width={'80px'}
      onClick={async () => {
        if (!info.isWorking || showLoading) return;
        setShowLoading(true);
        setStopingFn(true);
        console.log(1);
        try {
          await stop(info);
        } catch (err) {
          console.error(err);
          setStopingFn(false);
        }
        setShowLoading(false);
        console.log(2);
      }}
    >
      {showLoading ? (
        <Loading size={18} mr={'0px'} show={showLoading} />
      ) : (
        <span>Stop</span>
      )}
    </Btn>
  );
});

export default StopBtn;
