import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { getT2DFn, commafy } from '../../utils';
import { isMobile } from 'react-device-detect';

const TradeIntroduceCon = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  word-break: normal;
  background: #294046;
  border: 1px solid #42b5d9;
  color: #42b5d9;
  border-radius: 24px;
  padding: 14px 22px;
  white-space: nowrap;
  z-index: 10;
`;
const TxtLine = styled.div`
  display: flex;
`;
const Txt = styled.p`
  margin: 2px 0;
`;
const TxtName = styled(Txt)`
  width: 150px;
  ${isMobile &&
  css`
    width: 8.125rem;
  `}
`;
const TxtCon = styled(Txt)`
  flex: 1;
`;
const TxtNullCon = styled(TxtCon)`
  min-width: 30px;
  text-align: center;
`;

const TradeIntroduce = (props) => {
  const { info, item } = props;
  const { userSessions, supportedTokens } = useStreamTx();
  const tradeAddress = info.tradeAddress;
  const findUserItem = useMemo(() => {
    let userArr = [];
    userSessions.map((v) => (userArr = userArr.concat(Object.values(v))));
    const item = userArr.find(
      (v) => v.receiver.toLowerCase() === tradeAddress.toLowerCase()
    );
    return item;
  }, [tradeAddress, userSessions]);

  if (!findUserItem)
    return (
      <TradeIntroduceCon className='introduce'>
        <TxtLine>
          <TxtName>Path:</TxtName>
          <TxtCon>{info.name}</TxtCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Start Time:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>End Time:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Update Time:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Collateral Amount:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Collateral Asset:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Pending Amount:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Total Paid in Pair:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
        <TxtLine>
          <TxtName>Stream Rate:</TxtName>
          <TxtNullCon>-</TxtNullCon>
        </TxtLine>
      </TradeIntroduceCon>
    );
  let {
    startTime,
    endTime,
    updateTime,
    collateralAmount,
    pendingAmount,
    collateralAsset,
    paid,
    streamRate,
  } = findUserItem;

  const decimal = 10 ** item.decimals;
  startTime = getT2DFn(startTime * 1000);
  endTime = getT2DFn(endTime * 1000);
  updateTime = getT2DFn(updateTime * 1000);
  collateralAmount = commafy(collateralAmount / 10 ** 18);
  pendingAmount = commafy(pendingAmount / decimal);
  paid = commafy(paid / decimal);
  const itemSymbol = info.name.split('->')[0];
  streamRate = `${commafy((streamRate * 3600) / decimal)} ${itemSymbol}/h`;

  let collateralSymbol =
    collateralAsset === '0x0000000000000000000000000000000000000000'
      ? 'None'
      : supportedTokens.find((v) => v.address === collateralAsset)?.symbol;

  return (
    <TradeIntroduceCon className='introduce'>
      <TxtLine>
        <TxtName>Path:</TxtName>
        <TxtCon>{info.name}</TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Start Time:</TxtName>
        <TxtCon>{startTime}</TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>End Time:</TxtName>
        <TxtCon>{endTime}</TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Update Time:</TxtName>
        <TxtCon>{updateTime}</TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Collateral Amount:</TxtName>
        <TxtCon>{collateralAmount}</TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Collateral Asset:</TxtName>
        <TxtCon>{collateralSymbol}</TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Pending Amount:</TxtName>
        <TxtCon>
          {pendingAmount}&nbsp;{itemSymbol}
        </TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Total Paid in Pair:</TxtName>
        <TxtCon>
          {paid}&nbsp;{itemSymbol}
        </TxtCon>
      </TxtLine>
      <TxtLine>
        <TxtName>Stream Rate:</TxtName>
        <TxtCon>{streamRate}</TxtCon>
      </TxtLine>
    </TradeIntroduceCon>
  );
};

export default TradeIntroduce;
