import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import historyBtn from '../../static/history_btn.png';
import { isMobile } from 'react-device-detect';

const Btn = styled.button`
  width: 130px;
  height: 44px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  padding: 0px;
  margin: 0px;
  color: #5fdcf0;

  ${isMobile &&
  css`
    height: 2rem;
    width: 6.25rem;
  `}
`;
const Img = styled.img`
  width: 26px;
  margin-right: 8px;

  ${isMobile &&
  css`
    width: 1.25rem;
  `}
`;

function HistoryBtn(props) {
  const { showModalFn } = props;
  const { t } = useTranslation();
  return (
    <Btn onClick={showModalFn}>
      {/* <img alt="" style={{width: '26px', marginRight: '8px'}} src={historyBtn} /> */}
      <Img alt='' src={historyBtn}></Img>
      {t('History')}
    </Btn>
  );
}

export default HistoryBtn;
