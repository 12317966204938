import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { RowBetween } from '../Row';
import { X } from 'react-feather';
import { isMobile } from 'react-device-detect';

const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;
const Bg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
`;
const Con = styled.div`
  width: ${(props) => (props.width ? props.width : '500px')};
  background: #ffffff;
  border-radius: 16px;
  padding: 24px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${isMobile &&
  css`
    width: 92%;
  `}
`;
const CloseeBtn = styled(X)`
  cursor: pointer;
  display: ${(props) => props.banclose};
`;
const TitleLine = styled(RowBetween)`
  align-items: center;
  margin-bottom: ${(props) => (props.hideline ? '0' : '30px')};
  padding: 0 32px;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
    margin-bottom: 1.25rem;
  `}
`;
const Txt = styled.p`
  font-size: 22px;
  font-weight: 400;
  color: #243559;

  ${isMobile &&
  css`
    font-size: 1rem;
  `}
`;

const BaseModal = memo((props) => {
  const { closeFn, title, children, open, width, banClose = '' } = props;
  return (
    <Modal open={open}>
      <Bg onClick={closeFn} />
      <Con width={width} id='modal'>
        <TitleLine hideline={title && banClose}>
          <Txt>{title}</Txt>
          <CloseeBtn
            banclose={banClose}
            color={'#acacac'}
            size={isMobile ? 22 : 26}
            onClick={closeFn}
          />
        </TitleLine>
        {children}
      </Con>
    </Modal>
  );
});

export default BaseModal;
