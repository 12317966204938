import React, { useState, useEffect, memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from './Base';
// import ChooseModal from './Choose';
import { RowBetween, BaseRow } from '../Row';
import { SquareBtn } from '../Button';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { commafy, getItemFn } from '../../utils';
import Loading from '../Loading';
import { isMobile } from 'react-device-detect';
const BigNumber = require('bignumber.js');

const colorGreen = '#00A7C1';

const WithdrawContent = styled(BaseRow)`
  padding: 0 32px;
  flex-direction: column;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;

const InpBorder = styled(RowBetween)`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid ${colorGreen};
  display: flex;
  align-items: center;
`;
const CoinInp = styled.input`
  color: #243559;
  padding: 0;
  font-size: 32px;
  width: 60%;

  ::placeholder {
    color: #999999;
  }
`;

const Txt = styled.p`
  font-size: 16px;
  color: #243559;

  ${isMobile &&
  css`
    font-size: 0.875rem;
  `}
`;
const InpRight = styled.div`
  display: flex;
  align-items: center;
  color: ${colorGreen};
  font-size: 16px;
`;

const MaxBtn = styled(SquareBtn)`
  height: 24px;
  line-height: 24px;
  border-radius: 100px;
  background: ${colorGreen};
  color: #ffffff;
  padding: 0 12px;
  border: none;
`;

const Btn = styled(SquareBtn)`
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  height: 58px;
  border: none;
  background: ${(props) => (props.disabled ? '#999999' : colorGreen)};
  color: ${(props) => (props.disabled ? '#C1C1C1' : '#ffffff')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WithdrawModal = memo(({ open, curWithdraw, closeFn }) => {
  // const [allowWithdraw, setAllowWithdraw] = useState(false);
  // const [token, setToken] = useState(list[0]?.toToken);
  const { withdraw, supportedTokens, supportedPairs, userSessions } =
    useStreamTx();
  const [inpNum, setInpNum] = useState('');
  const [showLoading, setLoading] = useState(false);
  const [info, setInfo] = useState({});
  const [max, setMax] = useState('0');

  // useEffect(() => {
  //   if (token && inpNum)
  //     setAllowWithdraw(true)
  //   else
  //     setAllowWithdraw(false)
  // }, [token, inpNum])
  const checkWorkPaidList = useMemo(() => {
    const paidList = supportedPairs.filter(
      (v) => v.fromToken === info.address && v.isWorking
    );
    if (!paidList.length) return null;
    let userArr = [];
    userSessions.map((v) => (userArr = userArr.concat(Object.values(v))));
    const paidArr = [];
    paidList.map((v) => {
      const item = userArr.find(
        (value) => value.receiver.toLowerCase() === v.tradeAddress.toLowerCase()
      );
      return item && paidArr.push(item);
    });
    if (!paidArr.length) return null;
    return paidArr;
  }, [supportedPairs, userSessions, info.address]);
  const countWithNum = useMemo(() => {
    const paidArr = checkWorkPaidList;
    if (!paidArr) return info?.deposited;
    let count = 0;
    count = paidArr.reduce((a, b) => {
      const time =
        b.endTime - b.updateTime > 10 * 60 ? 600 : b.endTime - b.updateTime;
      const streamRate = new BigNumber(b.streamRate);
      const decimals = new BigNumber(String(10 ** info.decimals));
      const num = streamRate.dividedBy(decimals).multipliedBy(String(time));
      return num.plus(a);
    }, count);
    return info?.deposited.gt(count) ? info?.deposited?.minus(count) : '0';
  }, [checkWorkPaidList, info?.deposited, info.decimals]);
  const inpFn = (e) => {
    setInpNum(e?.target?.value);
  };
  const inpMaxFn = () => {
    setInpNum(max);
  };
  useEffect(() => {
    setInpNum('');
    setLoading(false);
  }, [open]);
  useEffect(() => {
    setInfo(getItemFn(supportedTokens, 'address', curWithdraw));
  }, [curWithdraw, supportedTokens, open]);
  useEffect(() => {
    setMax(countWithNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curWithdraw, open, info]);
  return (
    <BaseModal
      title={'Input Withdraws'}
      open={open}
      closeFn={closeFn}
      // child={
      //   !openChoose
      //     ?
      //       Content()
      //     :
      //       <ChooseModal list={list} chooseFn={token => {
      //         setToken(token)
      //         setOpenChoose(false)
      //       }} />
      // }
    >
      <WithdrawContent>
        {/* <InpBorder
          style={{marginBottom: '30px', cursor: 'pointer'}}
          onClick={() => {
            setOpenChoose(true)
          }}
        >
          <WithdrawLine>
            <DoubleCoinIcon></DoubleCoinIcon>
            {list.find(v => v.toToken === token)?.name}
          </WithdrawLine>
          <ChevronDown size={15} color={colorGreen} />
        </InpBorder> */}
        <InpBorder style={{ marginBottom: '10px' }}>
          <CoinInp value={inpNum} onChange={inpFn} placeholder='0' />
          <InpRight>
            <MaxBtn onClick={() => inpMaxFn()}>MAX</MaxBtn>&nbsp;
            <div>|&nbsp;{info?.symbol}</div>
          </InpRight>
        </InpBorder>
        <RowBetween style={{ marginBottom: isMobile ? '1.25rem' : '30px' }}>
          <Txt>Balance</Txt>
          <Txt>
            {commafy(max?.toString())}&nbsp;
            {info?.symbol?.toString()}
          </Txt>
        </RowBetween>

        <Btn
          disabled={!+inpNum || !max.gte(new BigNumber(inpNum)) || showLoading}
          onClick={async () => {
            if (!+inpNum || !max.gte(new BigNumber(inpNum)) || showLoading)
              return;
            setLoading(true);
            try {
              await withdraw(info, inpNum, true);
            } catch (addError) {
              // handle "add" error
              console.error('addError', addError);
              setLoading(false);
              return;
            }
            setLoading(false);
            closeFn();
          }}
        >
          <Loading size={28} show={showLoading} />
          Withdraw
        </Btn>
      </WithdrawContent>
    </BaseModal>
  );
});

export default WithdrawModal;
