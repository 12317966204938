import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from './Base';
import { RowBetween, BaseRow } from '../Row';
import { SquareBtn } from '../Button';
import { commafy } from '../../utils';
import { isMobile } from 'react-device-detect';
import { HeaderContext } from '../../view/Header';
const BigNumber = require('bignumber.js');

const colorBlue = '#5B86E5';

const StartTotal = styled(BaseRow)`
  flex-direction: column;
`;

const StartContent = styled(BaseRow)`
  padding: 0 32px;
  flex-direction: column;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;

const InpBorder = styled(RowBetween)`
  width: 100%;
  height: 100%;
  padding: 18px 20px;
  border-radius: 8px;
  border: 1px solid ${colorBlue};
  display: flex;
  align-items: center;
`;

const CoinInp = styled.input`
  color: #243559;
  font-size: 18px;
  flex: 1;
  padding-right: 8px;

  ::placeholder {
    color: #999999;
  }

  ${isMobile &&
  css`
    font-size: 0.875rem;
  `}
`;

const Txt = styled.p`
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#243559')};
  margin-bottom: ${(props) => (props.mt ? props.mt : 0)};

  ${isMobile &&
  css`
    font-size: 0.875rem;
  `}
`;

const InpRight = styled.div`
  display: flex;
  align-items: center;
  color: ${colorBlue};
  font-size: 16px;
`;

const MaxBtn = styled(SquareBtn)`
  height: 24px;
  line-height: 24px;
  border-radius: 100px;
  background: ${colorBlue};
  color: #ffffff;
  padding: 0 12px;
  border: none;
`;

const Btn = styled(SquareBtn)`
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  height: auto;
  line-height: unset;
  padding: 20px 0;
  border: none;
  background: ${(props) => (props.disabled ? '#999999' : colorBlue)};
  color: ${(props) => (props.disabled ? '#C1C1C1' : '#ffffff')};
`;

const Introduce = styled.p`
  text-align: center;
  padding: 20px 0;
  font-weight: 400;
  color: #999999;
  font-size: 14px;
  ${isMobile &&
  css`
    padding: 1.25rem;
  `}
`;

const TimeLine = styled(BaseRow)`
  justify-content: center;
  align-items: center;
`;

const Process = styled.div`
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  background: ${(props) => (props.active ? colorBlue : '#eee')};
  color: ${(props) => (props.active ? '#fff' : '#BDBDBD')};
`;

const ProcessLine = styled.div`
  width: 30px;
  height: 1px;
  background: #eee;
`;

export default function StartModal1({
  info,
  open,
  title,
  startNum,
  setStartNumFn,
  nextFn,
  closeFn,
}) {
  const [openWarn, setOpenWarn] = useState(false);

  const inpFn = (e) => {
    setStartNumFn(e?.target?.value);
    if (+e?.target?.value > +info?.deposited?.toString()) setOpenWarn(true);
    else setOpenWarn(false);
  };
  const inpMaxFn = () => {
    setStartNumFn(info?.deposited?.toString());
  };

  useEffect(() => {
    setOpenWarn(false);
  }, [open]);

  return (
    <BaseModal title={title} closeFn={closeFn} open={open}>
      <HeaderContext.Consumer>
        {(simpleModeType) => (
          <StartTotal>
            <StartContent style={{ borderBottom: '1px solid #eee' }}>
              {openWarn && (
                <Txt mt={'10px'} color={'#F05F5F'}>
                  Warning: Your deposits are not enough. Please deposit.{' '}
                </Txt>
              )}
              <InpBorder style={{ marginBottom: '20px' }}>
                <CoinInp
                  value={startNum}
                  onChange={inpFn}
                  placeholder='Input sell amount'
                />
                <InpRight>
                  <MaxBtn onClick={() => inpMaxFn()}>MAX</MaxBtn>
                  &nbsp;
                  <div>|&nbsp;{info?.symbol?.toString()}</div>
                </InpRight>
              </InpBorder>
              <Btn
                disabled={
                  !+startNum ||
                  (simpleModeType &&
                    new BigNumber(startNum).gt(info?.deposited))
                }
                style={{ marginBottom: '12px' }}
                onClick={() => {
                  if (
                    !+startNum ||
                    (simpleModeType &&
                      new BigNumber(startNum).gt(info?.deposited))
                  )
                    return;
                  nextFn();
                }}
              >
                Next
              </Btn>
              <RowBetween style={{ marginBottom: '20px' }}>
                <Txt>Balance</Txt>
                <Txt>
                  {commafy(info?.deposited?.toString())}&nbsp;
                  {info?.symbol?.toString()}
                </Txt>
              </RowBetween>
            </StartContent>
            <Introduce>
              After deposit, you can input the total amount you want to sell
              here.
            </Introduce>
            <TimeLine>
              <Process active={true}>1</Process>
              <ProcessLine />
              <Process>2</Process>
              <ProcessLine />
              <Process>3</Process>
            </TimeLine>
          </StartTotal>
        )}
      </HeaderContext.Consumer>
    </BaseModal>
  );
}
