import styled from 'styled-components';

const BaseBtn = styled.button`
  border-radius: 8px;
  border: ${(props) => (props.border ? props.border : 'none')};
  cursor: ${(props) =>
    props.disabled ? 'not-allowed' : props.load ? 'wait' : 'pointer'};
  background: ${(props) =>
    props.background ? props.background : 'rgba(0,0,0,0)'};
  padding: ${(props) => (props.padding ? props.padding : '0px')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  border-color: ${(props) =>
    props.disabled
      ? 'rgba(255, 255, 255, 0.2)'
      : props.color
      ? props.color
      : '#fff'};
  color: ${(props) =>
    props.disabled
      ? 'rgba(255, 255, 255, 0.2)'
      : props.color
      ? props.color
      : '#fff'};
`;

export const CircleBtn = styled(BaseBtn)`
  border-radius: 100px;
  color: #ffffff;
  background-image: linear-gradient(to right, #5b87e5, #5fdcf0);
  padding: 10px 24px;
  display: ${(props) => (props.hide ? 'none' : 'block')};
`;

export const SquareBtn = styled(BaseBtn)`
  border-radius: 8px;
  border: 1px solid;
  text-align: center;
  height: ${(props) => (props.height ? props.height : '30px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '30px')};
  width: ${(props) => props.width};
`;
