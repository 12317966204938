import React, { memo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Code, Info, DollarSign } from 'react-feather';
import { isMobile } from 'react-device-detect';
import MenuModal from './MenuModal';
import menu from '../../static/menu.png';

const MenuCon = styled.div`
  position: relative;
  cursor: pointer;
`;

const MenuBtn = styled.div`
  width: 40px;
  height: 40px;
  background: url(${menu}) no-repeat center;
  background-size: 26px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
`;

const MenuContent = styled.div`
  min-width: 9.125rem;
  background: #141d33;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 0.5rem;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  bottom: -1rem;
  right: 0rem;
  transform: translateY(100%);
  z-index: 1;

  ${isMobile &&
  css`
    bottom: 3.5rem;
    transform: translateY(0%);
  `}
`;

const MenuItemHTML = styled.p`
  display: flex;
  color: #fff;
  flex: 1;
  padding: 0.5rem 0.5rem;
  font-size: 16px;
  align-items: center;
  white-space: nowrap;

  > svg {
    margin-right: 8px;
  }
`;

const MenuItem = memo((props) => {
  const { href, closeFn, children } = props;

  const toPathFn = () => {
    window.open(href);
    closeFn();
  };

  return <MenuItemHTML onClick={toPathFn}>{children}</MenuItemHTML>;
});

// const MenuChooseModeItem = memo((props) => {
//   const {
//     modeType,
//     chooseHandal,
//     children
//   } = props;
//   return (
//     <MenuItemHTML onClick={() => chooseHandal(!modeType)}>
//       {children}
//     </MenuItemHTML>
//   )
// })

const Menu = memo((props) => {
  const { simpleModeType, setSimpleModeType } = props;

  const [openMenuContent, setOpenMenuContent] = useState(false);
  const [openMenuModal, setOpenMenuModal] = useState(false);

  const closeMenuFn = () => {
    setOpenMenuContent(false);
  };

  const openMenuFn = (e) => {
    e.preventDefault();
    setOpenMenuContent(true);
  };

  useEffect(() => {
    openMenuContent && document.addEventListener('click', closeMenuFn, false);
    return () => document.removeEventListener('click', closeMenuFn, false);
  }, [openMenuContent]);

  return (
    <>
      <MenuCon>
        <MenuBtn onClick={openMenuFn}>
          <MenuContent open={openMenuContent}>
            <MenuItem
              closeFn={closeMenuFn}
              href='https://mirror.xyz/0xfdF643def657D5CB689BD072AF7167328edFD955/MAgmtsjIfNmVA44H2gk-ClQP4paYXWredpjkSpz63NA'
            >
              <Info size={14} />
              Help
            </MenuItem>
            <MenuItem
              closeFn={closeMenuFn}
              href='https://github.com/wandevs/stream-trade-contracts'
            >
              <Code size={14} />
              Github
            </MenuItem>
            <MenuItem closeFn={closeMenuFn} href='https://wanlend.finance'>
              <DollarSign size={14} />
              WanLend
            </MenuItem>
            {/* <MenuChooseModeItem
              chooseHandal={ () => {
                simpleModeType && setOpenMenuModal(true);
                !simpleModeType && setSimpleModeType(!simpleModeType);
              } }
              simpleModeType={simpleModeType}>
              <Code size={14} />
              { !simpleModeType ? 'Toggle Simple Mode' : 'Toggle Expert Mode' }
            </MenuChooseModeItem> */}
          </MenuContent>
        </MenuBtn>
      </MenuCon>
      <MenuModal
        open={openMenuModal}
        closeFn={() => setOpenMenuModal(false)}
        chooseModeTypeFn={() => {
          setSimpleModeType(!simpleModeType);
          setOpenMenuModal(false);
        }}
      ></MenuModal>
    </>
  );
});

export default Menu;
