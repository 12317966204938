import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import SingleCoinIcon from '../../components/Icon/Single';
import { SquareBtn } from '../../components/Button';
import { getCoinUrlByNameFn, commafy } from '../../utils';
import { WalletContext } from '../../utils/wallet';
import WarnDepositsIsNotEnough from '../../components/Content/WarnDepositsIsNotEnough';
import { HeaderContext } from '../Header';
import TradeChild from '../../components/Content/TradeChild';

const colorBlue = '#5B86E5';
const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';
const colorGreen = '#00A7C1';

const TradeItemCon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;
const TradeItemS = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
`;
const Item = styled.div`
  display: flex;
  flex: ${(props) => props.fx};
  margin: ${(props) => (props.mr ? props.mr : '0 18px 0 0')};
  padding: ${(props) => (props.pd ? props.pd : '0px')};
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  width: ${(props) => props.w};
`;
const FixWidthCon = styled(Item)`
  width: 172px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
		font-size: 14px;
	`}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		font-size: 12px;
		width: 140px;
		flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    justify-content: ${(props) => (props.reverse ? 'center' : 'flex-start')};
	`}

  ${({ theme }) => theme.mediaWidth.upToMedium`
		width: 80px;
	`}
`;
const Txt = styled.p`
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  padding: ${(props) => (props.pd ? props.pd : '0')};
  font-weight: ${(props) => (props.fw ? props.fw : 'normal')};
`;
const Txt16 = styled(Txt)`
  font-size: 16px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    font-size: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 14px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
  `}
`;

const Txt20 = styled(Txt)`
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    font-size: 20px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
  `}
`;

const Btn = styled(SquareBtn)`
  border-radius: 8px;
  border: 1px solid;
  width: 80px;
  margin: ${(props) => (props.hasMr ? '0 12px 0 0' : '0')};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin: ${(props) => (props.hasMr ? '0 0 12px 0' : '0')};
    font-size: 12px;
	`}
`;
const TradeChildCon = styled.ul`
  display: flex;
  flex: ${(props) => (props.fx ? props.fx : 1)};
  padding: ${(props) => (props.pd ? props.pd : '0px')};
  margin: ${(props) => (props.pd ? props.mr : '0px')};
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : '#fff')};
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  width: 100%;
  flex-direction: column;
  position: relative;
  background-color: rgb(10, 15, 26);
`;
const TradeChildBgLi = styled.li`
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  max-width: 280px;
  height: 100%;
  background: #161b25;
  border-radius: 8px;
  z-index: 0;

  ${({ theme }) => theme.mediaWidth.upToMediumLarge`
      width: 35%;
    `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
      width: 36%;
    `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 41%;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 39%;
    `}
`;
const TradeChildLi = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const TradeChildItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const TradeItem = memo((props) => {
  const {
    item,
    unfold,
    index,
    len,
    setCurIndexFn,
    setStartIndexFn,
    chooseDepositFn,
    setOpenDepositsModalFn,
    chooseWithdrawFn,
    setOpenWithdrawModalFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    setOpenStartModal1Fn,
  } = props;

  const {
    supportedPairs,
    // supportedPairs,
    // userSessions
  } = useStreamTx();
  // console.log('df', userSessions)
  const arr = useMemo(() => {
    return supportedPairs.filter((v) => v.fromToken === item.address);
  }, [item.address, supportedPairs]);
  const supportedList = useMemo(() => {
    return !arr[0] ? [] : unfold ? arr : [arr[0]];
  }, [arr, unfold]);

  const depositFn = () => {
    chooseDepositFn(item.address);
    setOpenDepositsModalFn(true);
  };
  const withdrawFn = () => {
    chooseWithdrawFn(item.address);
    setOpenWithdrawModalFn(true);
  };
  const hasWorking = useMemo(() => {
    return arr.find((v) => v.isWorking) ? true : false;
  }, [arr]);

  return (
    <TradeItemCon>
      <WalletContext.Consumer>
        {(wallet) => (
          <TradeItemS>
            <Item fx={2}>
              <SingleCoinIcon
                url={getCoinUrlByNameFn(item.symbol, wallet.networkId)}
              />
              <Txt16>{item.symbol}</Txt16>
            </Item>
            <Item fx={2}>
              <Txt20 fw={'bold'}>{commafy(item.balance.toString())}</Txt20>
              &nbsp;
              <Txt16 color={colorFFFOpacity3}>{item.symbol}</Txt16>
            </Item>
            <Item fx={2}>
              <Txt20 fw={'bold'}>{commafy(item.deposited.toString())}</Txt20>
              &nbsp;
              <Txt16 color={colorFFFOpacity3}>{item.symbol}</Txt16>
              <WarnDepositsIsNotEnough
                arr={arr}
                index={index}
              ></WarnDepositsIsNotEnough>
            </Item>
            <FixWidthCon>
              <Btn
                color={colorBlue}
                width={'80px'}
                hasMr={true}
                onClick={depositFn}
              >
                Deposit
              </Btn>
              <HeaderContext.Consumer>
                {(simpleModeType) => (
                  <Btn
                    color={colorGreen}
                    width={'80px'}
                    disabled={Boolean(simpleModeType && hasWorking)}
                    onClick={() => {
                      if (simpleModeType && hasWorking) return;
                      withdrawFn();
                    }}
                  >
                    Withdraw
                  </Btn>
                )}
              </HeaderContext.Consumer>
            </FixWidthCon>
            {supportedList.length !== 0 ? (
              <TradeChildCon fx={11}>
                <TradeChildBgLi></TradeChildBgLi>
                {supportedList.map((v, k) => (
                  <TradeChildLi key={k}>
                    <TradeChild
                      unfold={unfold}
                      index={index}
                      hasBlock={arr.length !== 1 && !k}
                      info={v}
                      item={item}
                      setCurIndexFn={setCurIndexFn}
                      setStartIndexFn={setStartIndexFn}
                      setStartInfoFn={setStartInfoFn}
                      setStartNumFn={setStartNumFn}
                      setTimeFn={setTimeFn}
                      setOpenStartModal1Fn={setOpenStartModal1Fn}
                    />
                    {k !== supportedList.length - 1 && (
                      <p
                        style={{
                          width: '100%',
                          height: '1px',
                          background: 'rgba(255, 255, 255, 0)',
                          margin: '20px 0',
                        }}
                      ></p>
                    )}
                  </TradeChildLi>
                ))}
              </TradeChildCon>
            ) : (
              <TradeChildCon fx={11}>
                <TradeChildLi>
                  <TradeChildItem>
                    <Item fx={4}>
                      <Txt16 color={colorFFFOpacity3}>-</Txt16>
                    </Item>
                    <Item fx={2}>
                      <Txt16 color={colorFFFOpacity3}>-</Txt16>
                    </Item>
                    <Item fx={2}>
                      <Txt16 color={colorFFFOpacity3}>-</Txt16>
                    </Item>
                    <FixWidthCon pd={'0px'} mr={'0px'} reverse={true}>
                      <Txt16 color={colorFFFOpacity3}>-</Txt16>
                    </FixWidthCon>
                  </TradeChildItem>
                </TradeChildLi>
              </TradeChildCon>
            )}
          </TradeItemS>
        )}
      </WalletContext.Consumer>

      {index !== len && (
        <p
          style={{
            width: '100%',
            height: '1px',
            background: 'rgba(255, 255, 255, 0.1)',
            margin: '0 auto',
          }}
        ></p>
      )}
    </TradeItemCon>
  );
});

export default TradeItem;
