import React, { useMemo } from 'react';
import {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
} from 'styled-components';

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 1280,
  upToMedium: 1366,
  upToLarge: 1440,
  upToMediumLarge: 1600,
  upToNorLarge: 1540,
  upToSupLarge: 1920,
};

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    accumulator[size] = (a, b, c) => css`
      @media (max-width: ${MEDIA_WIDTHS[size]}px) {
        ${css(a, b, c)}
      }
    `;
    return accumulator;
  },
  {}
);

export function theme() {
  return {
    // media queries
    mediaWidth: mediaWidthTemplates,
  };
}

export default function ThemeProvider({ children }) {
  const themeObject = useMemo(() => theme(), []);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background:#e6e6e6 !important;
 
}
body
{
  background-image:none !important;
  
}
div[data-reach-dialog-content] a
{
  color:#0071bc ;
}
#swap-currency-input
{
  margin-bottom:-25px;
}
#swap-currency-output-2
{
  margin-top:-25px;
}
#logo-background
{
  /* background: linear-gradient(90deg, rgb(0, 169, 157) 0%, rgb(40, 171, 224) 100%);
  padding: 8px;
  padding-left:10px;
  padding-right:10px;
  position: absolute;
  top: 0px;
  padding-top: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
}
#center-logo
{
  margin-bottom:40px;
  zoom:0.8;
  position: relative;
    z-index: 1;
}

#animate-zoom
{
  display:inline-block;
  
  animation: blink-animation 0.4s infinite;
}
@keyframes blink-animation {
  form {
    transform:scale(1)
  }
  to {
    transform:scale(1.3)
  }
}


.token-amount-input::placeholder , .recipient-address-input::placeholder
{
  color:#999 !important;
}
#pseudo-loader
{
  position: absolute;
  filter: drop-shadow(10px 10px 10px #0000002e) drop-shadow(-10px -10px 10px #ffffff90);
  animation: none;
}  
@media only screen and (max-width: 1320px) {
  #logo-background
  {
    background:transparent !important;
  }
  #header-link
  {
    margin-left:0;
  }
  #shadow_bottom
		{
      opacity:0.6;
    }
    #center-logo
    {
      display:none
    }

    #logo-symbol
    {
      display:none;
    }
    #logo-full
    {
      display:block !important;
      margin:0 auto;
      margin-top:20px;
    }
    #logo-wrapper
    {
      margin-right:0px;
    }
    #body-wrapper
    {
     
    }
}
`;

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
}

body {
  min-height: 100vh;
  background-repeat: no-repeat;
}
`;
