import React, { memo } from 'react';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { AlertCircle } from 'react-feather';
import styled, { css } from 'styled-components';
import { isMobile } from '@wandevs/web3modal';

const DepositWarn = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 2px;

  &:hover .introduce {
    display: block;
  }
`;
const CheckDepositsIsEnoughTag = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -100%);
  word-break: normal;
  background: #294046;
  border: 1px solid #42b5d9;
  color: #f05f5f;
  border-radius: 24px;
  padding: 4px 12px;
  width: 200px;
  white-space: wrap;
  z-index: 10;

  ${isMobile &&
  css`
    top: 14px;
    left: 100%;
    transform: translate(-100%, 2%);
  `}
`;

const WarnDepositsIsNotEnough = memo((props) => {
  const { index, arr } = props;
  const { userSessions, supportedTokens } = useStreamTx();
  if (!userSessions.length) return null;
  if (!arr.length) return null;
  let totalPaid = 0;

  totalPaid = arr.reduce((a, b) => {
    const { tradeAddress, isWorking } = b;
    if (!isWorking) return a;
    let userArr = [];
    userSessions.map((v) => (userArr = userArr.concat(Object.values(v))));
    const item = userArr.find(
      (v) => v.receiver.toLowerCase() === tradeAddress.toLowerCase()
    );
    if (!item) return a;
    return (
      a +
      ((item.endTime - new Date().getTime() / 1000) * item.streamRate) /
        10 ** +supportedTokens[index].decimals
    );
  }, totalPaid);

  if (totalPaid > +supportedTokens[index]?.deposited?.toString())
    return (
      <DepositWarn>
        <AlertCircle size={14} color={'yellow'}></AlertCircle>
        <CheckDepositsIsEnoughTag className='introduce'>
          Warning: your deposits are not enough, when the deposits are
          exhausted, you will lose your collateral token.{' '}
        </CheckDepositsIsEnoughTag>
      </DepositWarn>
    );
  else return null;
});

export default WarnDepositsIsNotEnough;
