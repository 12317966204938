import React, { memo } from 'react';
import styled from 'styled-components';
import { RowBetween } from '../../components/Row';
// import Setting from '../../components/Setting';
import Menu from '../../components/Menu';
import WalletCom from '../../components/Wallet';

const FooterCon = styled(RowBetween)`
  width: 100%;
  align-items: center;
  padding: 0.75rem 0.9375rem;
  height: 3.5rem;
  background-color: #141d33;
`;

const Footer = memo((props) => {
  const { simpleModeType, setSimpleModeType, wallet, connectWallet } = props;
  return (
    <FooterCon>
      <WalletCom wallet={wallet} connectWallet={connectWallet} />
      <Menu
        simpleModeType={simpleModeType}
        setSimpleModeType={setSimpleModeType}
      />
    </FooterCon>
  );
});

export default Footer;
