import React, { useState } from 'react';
import styled from 'styled-components';
import { BaseRow, RowBetween } from '../Row';
import { getCoinUrlByAddressFn } from '../../utils';
import StopBtn from './StopBtn';
import StartBtn from './StartBtn';
import NameDirection from './NameDirection';
import TimeLeft from './TimeLeft';
import PaidContent from './Paid';
import TradeIntroduce from './TradeIntroduce';
import { SquareBtn } from '../Button';
import { ChevronDown } from 'react-feather';
import { WalletContext } from '../../utils/wallet';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';

const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';
const colorBlue = '#5B86E5';

const HideItem = styled.div`
  border-top: ${(props) =>
    props.showBorder ? '1px solid ' + colorFFFOpacity3 : 'none'};
  padding: 1.25rem 0 1.25rem;
  display: flex;
  flex-direction: column;
`;
const ItemLine = styled(RowBetween)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
`;
const DirectionItem = styled(ItemLine)`
  position: relative;
  cursor: pointer;
`;
const Item = styled(BaseRow)`
  flex: 1;
  margin-bottom: 0.5rem;
  align-items: center;
`;
const IntroduceItem = styled(BaseRow)`
  margin-bottom: 0.5rem;
  align-items: center;
  &:hover .introduce {
    display: block;
  }
`;
const UnfoldBtnItem = styled(Item)`
  flex-direction: row-reverse;
`;
const ItemRight = styled(Item)`
  flex-direction: ${(props) => (props.row ? 'row' : 'row-reverse')};
  justify-content: end;
  color: ${colorFFFOpacity3};
`;
const UnfoldBtn = styled(SquareBtn)`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 4px;
  border: 1px solid ${colorBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: ${(props) => (props.hasBlock ? 'visible' : 'hidden')};

  & .arrowIcon {
    transform: rotate(${(props) => (props.unfold ? '-180deg' : '0deg')});
  }
`;
const Txt14 = styled.p`
  font-size: 0.875rem;
  color: ${(props) => (props.color ? props.color : '#fff')};
  align-items: center;
`;

const MobileTradeChild = (props) => {
  const {
    v,
    k,
    index,
    item,
    unfold,
    hasBlock,
    setCurIndexFn,
    setStartIndexFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    setOpenStartModal1Fn,
  } = props;
  const [stoping, setStoping] = useState(false);
  const stopFn = () => {
    setStoping(false);
  };
  const { stop } = useStreamTx();
  const pathFn = (wallet, info) => {
    window.open(
      `https://${
        wallet.networkId === 999 ? 'testnet.' : ''
      }wanscan.org/address/${info.tradeAddress}`
    );
  };
  return (
    <HideItem showBorder={k}>
      <WalletContext.Consumer>
        {(wallet) => (
          <DirectionItem>
            <IntroduceItem>
              <TradeIntroduce info={v} item={item}></TradeIntroduce>
              <NameDirection
                src={[
                  getCoinUrlByAddressFn(v.fromToken, wallet.networkId),
                  getCoinUrlByAddressFn(v.toToken, wallet.networkId),
                ]}
                info={v}
                item={item}
                pathFn={() => {
                  pathFn(wallet, v);
                }}
              ></NameDirection>
            </IntroduceItem>
            <UnfoldBtnItem>
              <UnfoldBtn
                unfold={unfold}
                onClick={() => setCurIndexFn(unfold ? '-1' : index)}
                hasBlock={hasBlock}
              >
                <ChevronDown
                  size={20}
                  color={colorBlue}
                  className='arrowIcon'
                />
              </UnfoldBtn>
            </UnfoldBtnItem>
          </DirectionItem>
        )}
      </WalletContext.Consumer>
      <ItemLine>
        <Item>
          <Txt14 color={colorBlue}>Time Left</Txt14>
        </Item>
        <ItemRight>
          <TimeLeft tradeAddress={v.tradeAddress}></TimeLeft>
        </ItemRight>
      </ItemLine>
      <ItemLine>
        <Item>
          <Txt14 color={colorBlue}>Paid</Txt14>
        </Item>
        <ItemRight>
          <PaidContent
            tradeAddress={v.tradeAddress}
            index={index}
            curItem={item}
          ></PaidContent>
        </ItemRight>
      </ItemLine>
      <ItemLine>
        <StartBtn
          info={v}
          index={index}
          stoping={stoping}
          setStartIndexFn={(i) => setStartIndexFn(i)}
          setStartInfoFn={(v) => setStartInfoFn(v)}
          setStartNumFn={(v) => setStartNumFn(v)}
          setTimeFn={(v) => setTimeFn(v)}
          setOpenStartModal1Fn={(v) => setOpenStartModal1Fn(v)}
          setStopingFn={stopFn}
        ></StartBtn>
        <StopBtn
          info={v}
          stop={stop}
          setStopingFn={(i) => setStoping(i)}
        ></StopBtn>
      </ItemLine>
    </HideItem>
  );
};

export default MobileTradeChild;
