import React, { memo } from 'react';
import TradeItem from './TradeItem';

const TradeList = memo((props) => {
  const {
    tokenList,
    curIndex,
    setCurIndexFn,
    setStartIndexFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    chooseDepositFn,
    chooseWithdrawFn,
    setOpenWithdrawModalFn,
    setOpenDepositsModalFn,
    setOpenStartModal1Fn,
  } = props;
  return (
    <>
      {tokenList.map((item, index) => (
        <TradeItem
          key={item.address}
          item={item}
          unfold={index === curIndex}
          index={index}
          len={tokenList.length - 1}
          setCurIndexFn={(i) => setCurIndexFn(i)}
          setStartIndexFn={(i) => setStartIndexFn(i)}
          setStartInfoFn={(i) => setStartInfoFn(i)}
          setStartNumFn={(i) => setStartNumFn(i)}
          setTimeFn={(i) => setTimeFn(i)}
          chooseDepositFn={(i) => chooseDepositFn(i)}
          chooseWithdrawFn={(i) => chooseWithdrawFn(i)}
          setOpenDepositsModalFn={(i) => setOpenDepositsModalFn(i)}
          setOpenWithdrawModalFn={(i) => setOpenWithdrawModalFn(i)}
          setOpenStartModal1Fn={(i) => setOpenStartModal1Fn(i)}
        />
      ))}
    </>
  );
});

export default TradeList;
