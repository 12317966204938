import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from '../Modal/Base';
import { SquareBtn } from '../Button';
import { isMobile } from 'react-device-detect';

const colorBlue = '#5B86E5';
const colorGreen = '#00A7C1';

const Content = styled.div`
  padding: 0 32px;
  word-break: break-all;
  margin-bottom: 40px;
  font-size: 20px;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;
const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  margin-bottom: 10px;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;
const BtnBase = styled(SquareBtn)`
  width: 48%;
  height: 50px;
  font-size: 18px;
`;
const CancelBtn = styled(BtnBase)`
  border-color: ${colorGreen};
  background-color: ${colorGreen};
`;

const ConfirmBtn = styled(BtnBase)`
  border-color: ${colorBlue};
  background-color: ${colorBlue};
`;

const MenuModal = memo((props) => {
  const { closeFn, chooseModeTypeFn, open } = props;

  return (
    <BaseModal
      title={''}
      closeFn={closeFn}
      open={open}
      banClose={'none'}
      chooseModeTypeFn
    >
      <Content>
        Do you want to enable Expert Mode? Expert mode will allow overflow start
        and non-stop withdraw. Use at your own risk.
      </Content>
      <BtnGroup>
        <ConfirmBtn onClick={chooseModeTypeFn}>Confirm</ConfirmBtn>
        <CancelBtn onClick={closeFn}>Cancel</CancelBtn>
      </BtnGroup>
    </BaseModal>
  );
});

export default MenuModal;
