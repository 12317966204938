import React, { useState, useEffect, memo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from './Base';
// import ChooseModal from './Choose';
import { RowBetween, BaseRow } from '../Row';
import { SquareBtn } from '../Button';
// import DoubleCoinIcon from '../Icon/Double';
// import { ChevronDown } from 'react-feather';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { commafy, getItemFn } from '../../utils';
import Loading from '../Loading';
import { isMobile } from 'react-device-detect';
const BigNumber = require('bignumber.js');

const colorBlue = '#5B86E5';

const DepositContent = styled(BaseRow)`
  padding: 0 32px;
  flex-direction: column;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;

const InpBorder = styled(RowBetween)`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid ${colorBlue};
  display: flex;
  align-items: center;
`;
const CoinInp = styled.input`
  color: #243559;
  padding: 0;
  font-size: 32px;
  width: 60%;

  ::placeholder {
    color: #999999;
  }
`;

const Txt = styled.p`
  font-size: 16px;
  color: #243559;

  ${isMobile &&
  css`
    font-size: 0.875rem;
  `}
`;
const InpRight = styled.div`
  display: flex;
  align-items: center;
  color: ${colorBlue};
  font-size: 16px;
`;

const MaxBtn = styled(SquareBtn)`
  height: 24px;
  line-height: 24px;
  border-radius: 100px;
  background: ${colorBlue};
  color: #ffffff;
  padding: 0 12px;
  border: none;
`;

const Btn = styled(SquareBtn)`
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  height: 58px;
  padding: 20px 0;
  border: none;
  background: ${(props) => (props.disabled ? '#999999' : colorBlue)};
  color: ${(props) => (props.disabled ? '#C1C1C1' : '#ffffff')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DepositLine = styled(BaseRow)`
//   align-items: center;
// `;

const DepositModal = memo(
  ({
    curDeposit,
    // list=[],
    open,
    closeFn,
  }) => {
    const { deposit, approve, supportedTokens } = useStreamTx();

    // const info = supportedTokens[curDeposit]

    const [inpNum, setInpNum] = useState('');
    const [showLoading, setLoading] = useState(false);
    const [info, setInfo] = useState({});

    const inpFn = useCallback((e) => {
      setInpNum(e?.target?.value);
    }, []);

    const inpMaxFn = useCallback(() => {
      let maxNum = 0;
      if (info?.symbol === 'WAN') {
        const maxShouldNum = info?.balance.minus('0.1');
        maxNum = maxShouldNum.gt('0') ? maxShouldNum : 0;
      } else {
        maxNum = info?.balance;
      }
      setInpNum(maxNum.toString());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info]);
    useEffect(() => {
      setInpNum('');
      setLoading(false);
    }, [open]);
    useEffect(() => {
      setInfo(getItemFn(supportedTokens, 'address', curDeposit));
    }, [curDeposit, supportedTokens]);
    return (
      <BaseModal title={'Input Deposits'} closeFn={closeFn} open={open}>
        <DepositContent>
          {/* <InpBorder
          style={{marginBottom: '30px', cursor: 'pointer'}}
          onClick={() => {
            setOpenChoose(true)
          }}
        >
          <DepositLine>
            <DoubleCoinIcon></DoubleCoinIcon>
            {list.find(v => v.toToken === token)?.name}
          </DepositLine>
          <ChevronDown size={15} color={colorBlue} />
        </InpBorder> */}
          <InpBorder style={{ marginBottom: '10px' }}>
            <CoinInp value={inpNum} onChange={inpFn} placeholder='0' />
            <InpRight>
              <MaxBtn onClick={inpMaxFn}>MAX</MaxBtn>&nbsp;
              <div>|&nbsp;{info?.symbol}</div>
            </InpRight>
          </InpBorder>
          <RowBetween style={{ marginBottom: isMobile ? '1.25rem' : '30px' }}>
            <Txt>Balance</Txt>
            <Txt>
              {commafy(info?.balance?.toString())}&nbsp;
              {info?.symbol?.toString()}
            </Txt>
          </RowBetween>
          <Btn
            disabled={
              !+inpNum ||
              !info?.balance.gte(new BigNumber(inpNum)) ||
              showLoading
            }
            onClick={async () => {
              if (
                !+inpNum ||
                !info?.balance.gte(new BigNumber(inpNum)) ||
                showLoading
              )
                return;
              setLoading(true);
              try {
                if (info.approved) {
                  await deposit(info, inpNum);
                } else {
                  await approve(info.address);
                  setLoading(false);
                  return;
                }
              } catch (addError) {
                // handle "add" error
                console.error('addError', addError);
                setLoading(false);
                return;
              }
              setLoading(false);
              closeFn();
            }}
          >
            <Loading size={28} show={showLoading} />
            {info?.approved ? 'Deposit' : 'Approve'}
          </Btn>
        </DepositContent>
      </BaseModal>
    );
  }
);

export default DepositModal;
