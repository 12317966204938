const limitTradeMinAmountConfig = [
  // test
  {
    chainId: 999,
    address: '0x89a3e1494bc3db81dadc893ded7476d33d47dcbd',
    name: 'OBTC',
    symbol: 'OBTC',
    decimals: 8,
    limitAmount: 0.001,
  },
  {
    chainId: 999,
    address: '0x3c653971ffc0794CB2fC5DF5D47576BEdCE149B3',
    name: 'BTC',
    symbol: 'BTC',
    decimals: 8,
    limitAmount: 0.001,
  },
  {
    chainId: 999,
    address: '0x0f6be49eB9d86f97dE0EB759c856bFb0db8316f7',
    name: 'USDT',
    symbol: 'USDT',
    decimals: 6,
    limitAmount: 0.1,
  },
  {
    chainId: 999,
    address: '0x0000000000000000000000000000000000000000',
    name: 'WAN',
    symbol: 'WWAN',
    decimals: 18,
    limitAmount: 0.1,
  },

  // main
  {
    chainId: 888,
    address: '0x50c439B6d602297252505a6799d84eA5928bCFb6',
    name: 'wanBTC',
    symbol: 'wanBTC',
    decimals: 8,
    limitAmount: 0.001,
  },
  {
    chainId: 888,
    address: '0x11e77E27Af5539872efEd10abaA0b408cfd9fBBD',
    name: 'wanUSDT',
    symbol: 'wanUSDT',
    decimals: 6,
    limitAmount: 0.1,
  },
  {
    chainId: 888,
    address: '0x0000000000000000000000000000000000000000',
    name: 'WAN',
    symbol: 'WWAN',
    decimals: 18,
    limitAmount: 0.1,
  },
];

export default limitTradeMinAmountConfig;
