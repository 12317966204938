import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const CoinIcon = styled.div`
  display: block;
  margin: ${(props) => (props.margin ? props.margin : '0 8px 0 0')};
  z-index: ${(props) => (props.zIndex ? props.zIndex : '0')};
  border-radius: 50%;
  background-image: url(${(props) => (props.url ? props.url : '')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 36px;
  height: 36px;
  position: relative;
  cursor: ${(props) => (props.cursor ? 'pointer' : 'default')};

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
		width: 36px;
		height: 36px;
	`}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 30px;
		height: 30px;
	`}

  ${({ theme }) => theme.mediaWidth.upToMedium`
		width: 22px;
		height: 22px;
	`}

	width: ${(props) => props.size}${isMobile ? 'rem' : 'px'} !important;
  height: ${(props) => props.size}${isMobile ? 'rem' : 'px'} !important;

  &:hover .tag {
    display: block;
  }
`;
const Tag = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  word-break: normal;
  background: #294046;
  border: 1px solid #42b5d9;
  color: #42b5d9;
  border-radius: 24px;
  padding: 4px 12px;
  white-space: nowrap;
`;

export default function SingleCoinIcon({
  size,
  margin,
  zIndex,
  url,
  tag = false,
  cursor = '',
  handle = void 0,
}) {
  return (
    <CoinIcon
      url={url}
      size={size}
      margin={margin}
      zIndex={zIndex}
      tag={tag}
      cursor={cursor}
      onClick={handle}
    >
      {tag && <Tag className='tag'>{tag}</Tag>}
    </CoinIcon>
  );
}
