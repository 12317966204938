import React, { useEffect, useState, memo } from 'react';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import styled, { css } from 'styled-components';
import DepositModal from '../../components/Modal/Deposit';
import WithdrawModal from '../../components/Modal/Withdraw';
import StartModal1 from '../../components/Modal/Start1';
import StartModal2 from '../../components/Modal/Start2';
import StartModal3 from '../../components/Modal/Start3';
import Title from './Title';
import TradeList from './TradeList';
import MobileTradeList from './MobileTradeList';
import { isMobile } from 'react-device-detect';

const TradeCon = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  ${isMobile &&
  css`
    border: none;
  `}

  margin-bottom: 100px;
`;
const ListCon = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = memo((props) => {
  const { supportedTokens, supportedPairs } = useStreamTx();
  const [curIndex, setCurIndex] = useState(-1);
  const [curDeposit, setCurDeposit] = useState('');
  const [curWithdraw, setCurWithdraw] = useState(0);
  const [curStartIndex, setStartIndex] = useState(0);
  const [openDepositisModal, setOpenDepositsModal] = useState(false);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [startNum, setStartNum] = useState('');
  const [openStartModal1, setOpenStartModal1] = useState(false);
  const [time, setTime] = useState(0);
  const [openStartModal2, setOpenStartModal2] = useState(false);
  const [openStartModal3, setOpenStartModal3] = useState(false);
  const [startInfo, setStartInfo] = useState({});
  const [searchInp, setSearchInp] = useState('');
  const [tokenList, setTokenList] = useState(supportedTokens);
  useEffect(() => {
    const arr = supportedTokens.filter((v) => {
      const reg = new RegExp(searchInp.toLocaleLowerCase());
      const checkToken =
        reg.test(v.symbol.toLocaleLowerCase()) ||
        reg.test(v.address.toLocaleLowerCase());
      const pairArr = supportedPairs.filter(
        (item) => item.fromToken === v.address
      );
      if (!pairArr.length) return checkToken;
      const outputArr = pairArr.filter((item) => {
        const outNameArr = item.name.split('->');
        return (
          reg.test(item.toToken.toLocaleLowerCase()) ||
          reg.test(outNameArr[outNameArr.length - 1].toLocaleLowerCase())
        );
      });
      return checkToken || outputArr.length;
    });
    setTokenList(arr);
  }, [searchInp, supportedPairs, supportedTokens]);
  return (
    <TradeCon>
      <DepositModal
        open={openDepositisModal}
        curDeposit={curDeposit}
        closeFn={() => setOpenDepositsModal(false)}
      ></DepositModal>
      <WithdrawModal
        open={openWithdrawModal}
        curWithdraw={curWithdraw}
        closeFn={() => setOpenWithdrawModal(false)}
      ></WithdrawModal>
      <StartModal1
        open={openStartModal1}
        info={tokenList[curStartIndex]}
        title={startInfo.name}
        closeFn={() => setOpenStartModal1(false)}
        startNum={startNum}
        setStartNumFn={(inpNum) => setStartNum(String(inpNum))}
        nextFn={() => {
          setOpenStartModal2(true);
          setOpenStartModal1(false);
          setOpenStartModal3(false);
        }}
      ></StartModal1>
      <StartModal2
        time={time}
        open={openStartModal2}
        info={startInfo}
        startNum={startNum}
        title={startInfo.name}
        setTimeFn={(t) => setTime(Number(t))}
        nextFn={() => {
          setOpenStartModal3(true);
          setOpenStartModal1(false);
          setOpenStartModal2(false);
        }}
        closeFn={() => setOpenStartModal2(false)}
      ></StartModal2>
      <StartModal3
        info={startInfo}
        open={openStartModal3}
        closeFn={() => setOpenStartModal3(false)}
        startNum={startNum}
        time={time}
        baseCoin={startInfo.name}
        startFn={() => {
          setOpenStartModal1(false);
          setOpenStartModal2(false);
          setOpenStartModal3(false);
        }}
      ></StartModal3>
      {isMobile ? (
        <MobileTradeList
          tokenList={tokenList}
          curIndex={curIndex}
          setCurIndexFn={(i) => setCurIndex(Number(i))}
          setStartIndexFn={(i) => setStartIndex(i)}
          chooseDepositFn={(i) => setCurDeposit(i)}
          chooseWithdrawFn={(i) => setCurWithdraw(i)}
          setStartInfoFn={(v) => setStartInfo(v)}
          setStartNumFn={(i) => setStartNum(String(i))}
          setTimeFn={(i) => setTime(Number(i))}
          setOpenDepositsModalFn={(i) => setOpenDepositsModal(Boolean(i))}
          setOpenWithdrawModalFn={(i) => setOpenWithdrawModal(Boolean(i))}
          setOpenStartModal1Fn={(i) => {
            setStartNum('');
            setTime(0);
            setOpenStartModal1(Boolean(i));
          }}
        ></MobileTradeList>
      ) : (
        <ListCon>
          <Title
            searchInp={searchInp}
            changeValueFn={(value) => setSearchInp(String(value))}
          />
          <TradeList
            tokenList={tokenList}
            curIndex={curIndex}
            setCurIndexFn={(i) => setCurIndex(Number(i))}
            setStartIndexFn={(i) => setStartIndex(i)}
            chooseDepositFn={(i) => setCurDeposit(i)}
            chooseWithdrawFn={(i) => setCurWithdraw(i)}
            setStartInfoFn={(v) => setStartInfo(v)}
            setStartNumFn={(i) => setStartNum(String(i))}
            setTimeFn={(i) => setTime(Number(i))}
            setOpenDepositsModalFn={(i) => setOpenDepositsModal(Boolean(i))}
            setOpenWithdrawModalFn={(i) => setOpenWithdrawModal(Boolean(i))}
            setOpenStartModal1Fn={(i) => {
              setStartNum('');
              setTime(0);
              setOpenStartModal1(Boolean(i));
            }}
          ></TradeList>
        </ListCon>
      )}
    </TradeCon>
  );
});

export default Container;
