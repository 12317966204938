import React, { memo, useState, useMemo, useEffect } from 'react';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { getTimeFn } from '../../utils';

const TimeUpdate = (props) => {
  const { time } = props;
  const [updateTime, setUpdateTime] = useState(
    time - new Date().getTime() / 1000
  );
  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTime(time - new Date().getTime() / 1000);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);
  return <span>{getTimeFn(updateTime)}</span>;
};
const TimeLeft = memo(({ tradeAddress }) => {
  const { userSessions } = useStreamTx();
  const findUserItem = useMemo(() => {
    let userArr = [];
    userSessions.map((v) => (userArr = userArr.concat(Object.values(v))));
    const item = userArr.find(
      (v) => v.receiver.toLowerCase() === tradeAddress.toLowerCase()
    );
    return item;
  }, [tradeAddress, userSessions]);
  if (!userSessions.length) return '-';
  if (!findUserItem) return '-';
  // console.log(findUserItem)
  if (!findUserItem.enable) return '-';
  const leftTime = findUserItem.endTime - new Date().getTime() / 1000;
  if (!leftTime || leftTime < 0) return '-';
  return <TimeUpdate time={findUserItem.endTime}></TimeUpdate>;
});

export default TimeLeft;
