import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { BaseRow } from '../../components/Row';
import introduce1 from '../../static/introduce1.png';
import introduce2 from '../../static/introduce2.png';
import introduce3 from '../../static/introduce3.png';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import SingleCoinIcon from '../../components/Icon/Single';
import { getCoinUrlByNameFn } from '../../utils';
import { isMobile } from 'react-device-detect';
import { WalletContext } from '../../utils/wallet';

const IntroduceCon = styled.div`
  width: 100%;
  padding-top: 10px;
  color: #fff;
  margin-bottom: 20px;

  ${isMobile &&
  css`
    padding-top: 0;
  `}
`;

const Border = styled.div`
  padding: 24px 40px 20px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  ${isMobile &&
  css`
    padding: 1.25rem;
  `}
`;

const Product = styled(BaseRow)`
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;

  ${isMobile &&
  css`
    flex-direction: column;
    padding-bottom: 0;
    margin-bottom: 1.125rem;
  `}
`;

const ProductItem = styled(BaseRow)`
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 30px;
  margin-right: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
  `}

  ${isMobile &&
  css`
    flex: 1;
    margin-bottom: 1rem;
    font-size: 0.875rem;
  `}
`;

const ProductIcon = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 18px;
    height: 18px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 16px;
    height: 16px;
  `}

  ${isMobile &&
  css`
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  `}
`;

const Support = styled(BaseRow)`
  align-items: center;
  font-size: 14px;

  ${isMobile &&
  css`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const SupportGroup = styled(BaseRow)`
  flex: 1;
  flex-wrap: wrap;

  ${isMobile &&
  css`
    padding-top: 1rem;
  `}
`;

const GradientTxt = styled.h3`
  position: relative;
  font-weight: bold;
  font-size: 36px;
  color: #5fdcf0;
  background: linear-gradient(270deg, #5b86e5 0%, #5fdcf0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${isMobile &&
  css`
    font-size: 1.5rem;
  `}
`;

const TxtContent = styled.p`
  font-size: 18px;
  padding-top: 14px;

  ${isMobile &&
  css`
    font-size: 0.875rem;
    padding-top: 0.625rem;
  `}
`;

const TxtNote = styled.p`
  font-size: 14px;
  padding-top: 10px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.3);

  ${isMobile &&
  css`
    font-size: 0.75rem;
    padding-top: 0.625rem;
  `}
`;

function Introduce() {
  const { t } = useTranslation();
  const { supportedTokens } = useStreamTx();
  return (
    <IntroduceCon>
      <WalletContext.Consumer>
        {(wallet) => (
          <Border>
            <GradientTxt>{t('Introduce.title')}</GradientTxt>
            <TxtContent>{t('Introduce.content')}</TxtContent>
            <TxtNote></TxtNote>
            <Product>
              <ProductItem>
                <ProductIcon url={introduce1} />
                <span>{t('Introduce.type.type')}</span>
              </ProductItem>
              <ProductItem>
                <ProductIcon url={introduce2} />
                <span>{t('Introduce.type.time')}</span>
              </ProductItem>
              <ProductItem>
                <ProductIcon url={introduce3} />
                <span>{t('Introduce.type.pay')}</span>
              </ProductItem>
            </Product>
            <Support>
              <span>{t('Introduce.support')} &nbsp;&nbsp;&nbsp;</span>
              <SupportGroup>
                {supportedTokens.map((item, index) => (
                  <SingleCoinIcon
                    size={isMobile ? 1.625 : 26}
                    margin={isMobile ? '0 16px 8px 0' : '0 16px 0 0'}
                    key={index}
                    url={getCoinUrlByNameFn(item.symbol, wallet.networkId)}
                    tag={
                      item.symbol.toLocaleLowerCase() === 'wan'
                        ? ''
                        : `Add ${item.symbol} to MetaMask`
                    }
                    cursor={'has'}
                    handle={() => {
                      if (item.symbol.toLocaleLowerCase() === 'wan') return;
                      wallet.addToken(
                        item.address,
                        item.symbol,
                        item.decimals,
                        getCoinUrlByNameFn(item.symbol, wallet.networkId)
                      );
                    }}
                  />
                ))}
              </SupportGroup>
            </Support>
          </Border>
        )}
      </WalletContext.Consumer>
    </IntroduceCon>
  );
}

export default Introduce;
