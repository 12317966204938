import defaultImg from './static/coinIcon/default.png';

const mainSrc = [
  {
    chainId: 888,
    address: '0x8B9F9f4aA70B1B0d586BE8aDFb19c1Ac38e05E9a',
    name: 'WASP',
    symbol: 'WASP',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WASP.png',
  },
  {
    chainId: 888,
    address: '0x924fd608bf30dB9B099927492FDA5997d7CFcb02',
    name: 'WASPv2',
    symbol: 'WASPv2',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WASP_V2.svg',
  },
  {
    chainId: 888,
    address: '0x230f0C01b8e2c027459781E6a56dA7e1876EFDbe',
    name: 'WAND',
    symbol: 'WAND',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WAND.png',
  },
  {
    chainId: 888,
    address: '0xE3aE74D1518A76715aB4C7BeDF1af73893cd435A',
    name: 'wanETH',
    symbol: 'wanETH',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanETH.png',
  },
  {
    chainId: 888,
    address: '0x50c439B6d602297252505a6799d84eA5928bCFb6',
    name: 'wanBTC',
    symbol: 'wanBTC',
    decimals: 8,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanBTC.png',
  },
  {
    chainId: 888,
    address: '0x81862b7622ced0defb652addd4e0c110205b0040',
    name: 'EOS',
    symbol: 'wanEOS',
    decimals: 4,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanEOS.png',
  },
  {
    chainId: 888,
    address: '0x11e77E27Af5539872efEd10abaA0b408cfd9fBBD',
    name: 'wanUSDT',
    symbol: 'wanUSDT',
    decimals: 6,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanUSDT.png',
  },
  {
    chainId: 888,
    address: '0x0000000000000000000000000000000000000000',
    name: 'WAN',
    symbol: 'WWAN',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WAN.png',
  },
  {
    chainId: 888,
    address: '0x52A9CEA01c4CBDd669883e41758B8eB8e8E2B34b',
    name: 'wanUSDC',
    symbol: 'wanUSDC',
    decimals: 6,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanUSDC.png',
  },
  {
    chainId: 888,
    address: '0x06da85475f9d2ae79af300de474968cd5a4fde61',
    name: 'LINK',
    symbol: 'wanLINK',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanLINK.png',
  },
  {
    chainId: 888,
    address: '0x73eaa7431b11b1e7a7d5310de470de09883529df',
    name: 'UNI',
    symbol: 'wanUNI',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanUNI.png',
  },
  {
    chainId: 888,
    address: '0x9b6863f6ab2047069ad1cd15fff8c45af637d67c',
    name: 'SUSHI',
    symbol: 'wanSUSHI',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanSUSHI.png',
  },
  {
    chainId: 888,
    address: '0xf1d0ad0c4a612ecf4931b673245f1fc2935bccdc',
    name: 'ZCN',
    symbol: 'wanZCN',
    decimals: 10,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanZCN.png',
  },
  {
    chainId: 888,
    address: '0xde1a20792553b84ddb254ca78fa7c5996ad5fbe2',
    name: 'VIBE',
    symbol: 'wanVIBE',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanVIBE.png',
  },
  {
    chainId: 888,
    address: '0xa31b67a8cba75ea6ced8340d8bc0431ab052a4fa',
    name: 'MKR',
    symbol: 'wanMKR',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanMKR.png',
  },
  {
    chainId: 888,
    address: '0xf665E0e3E75D16466345E1129530ec28839EfaEa',
    name: 'wanXRP',
    symbol: 'wanXRP',
    decimals: 6,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanXRP.png',
  },
  {
    chainId: 888,
    address: '0x6e11655d6aB3781C6613db8CB1Bc3deE9a7e111F',
    name: 'ZOO',
    symbol: 'ZOO',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/ZOO.png',
  },
  {
    chainId: 888,
    address: '0x8f587d955f3cf200290426f91a7c3687787ed703',
    name: 'OCEAN',
    symbol: 'wanOCEAN',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanOCEAN.png',
  },
  {
    chainId: 888,
    address: '0xD3a33C6fEa7F785DdC0915f6A76919C11AbdED45',
    name: 'wanDOGE',
    symbol: 'wanDOGE',
    decimals: 8,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanDOGE.png',
  },
  {
    chainId: 888,
    address: '0x5f95A1AB4fa5f367708F12371Ad954D95b55F395',
    name: 'ICP',
    symbol: 'wanICP',
    decimals: 8,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanICP.png',
  },
  {
    chainId: 888,
    address: '0xf17c59bf0f6326da7a8cc2ce417e4f53a26707bd',
    name: 'PHX',
    symbol: 'PHX',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/PHX.png',
  },
  {
    chainId: 888,
    address: '0xd8e7bd03920BA407D764789B11DD2B5EAeE0961e',
    name: 'wanLTC',
    symbol: 'wanLTC',
    decimals: 8,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanLTC.png',
  },
  {
    chainId: 888,
    address: '0x716f88d32B52342AF040B2E775871dFF56EBd035',
    name: 'CFi',
    symbol: 'wanCFi',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanCFI.png',
  },
  {
    chainId: 888,
    address: '0x114FA1201F82B83c5a2FF0465b4024f01f966b91',
    name: 'MOVR',
    symbol: 'wanMOVR',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanMOVR.png',
  },
  {
    chainId: 888,
    address: '0xB333721251961337F67bbBCAED514f9F284CE8E8',
    name: 'AVAX',
    symbol: 'wanAVAX',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanAVAX.png',
  },
  {
    chainId: 888,
    address: '0x52f44783BdF480e88C0eD4cF341A933CAcfDBcaa',
    name: 'wanDOT',
    symbol: 'wanDOT',
    decimals: 10,
    logoURI: 'https://icons.wanswap.finance/icon/others/DOT.png',
  },
  {
    chainId: 888,
    address: '0xaACB8505b680dD287eCDE9679C72aa5FEF2e9B0c',
    name: 'ECR',
    symbol: 'wanECR',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanECR.png',
  },
  {
    chainId: 888,
    address: '0xB24999Cf67e4EACBF164BcE9138136F33589d969',
    name: 'VOX',
    symbol: 'VOX',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/others/VOX.png',
  },
  {
    chainId: 888,
    address: '0x9DE0405064BEDd88399098b4fbb2f7fA462992E0',
    name: 'BNB',
    symbol: 'wanBNB',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/others/BNB.png',
  },
];

const testSrc = [
  {
    chainId: 999,
    address: '0x974ab46969d3d9a4569546051a797729e301d6eb',
    name: 'FNX',
    symbol: 'FNX',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/FNX.png',
  },
  {
    chainId: 999,
    address: '0x48344649B9611a891987b2Db33fAada3AC1d05eC',
    name: 'wanETH',
    symbol: 'ETH',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanETH.png',
  },
  {
    chainId: 999,
    address: '0x89a3e1494bc3db81dadc893ded7476d33d47dcbd',
    name: 'OBTC',
    symbol: 'OBTC',
    decimals: 8,
    logoURI: 'https://icons.wanswap.finance/icon/wanBTC.png',
  },
  {
    chainId: 999,
    address: '0x3c653971ffc0794CB2fC5DF5D47576BEdCE149B3',
    name: 'BTC',
    symbol: 'BTC',
    decimals: 8,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanBTC.png',
  },
  {
    chainId: 999,
    address: '0x57195b9d12421e963b720020483f97bb7ff2e2a6',
    name: 'Crosschain EOS',
    symbol: 'EOS',
    decimals: 4,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanEOS.png',
  },
  {
    chainId: 999,
    address: '0x0f6be49eB9d86f97dE0EB759c856bFb0db8316f7',
    name: 'USDT',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanUSDT.png',
  },
  {
    chainId: 999,
    address: '0x7ff465746e4f47e1cbbb80c864cd7de9f13337fe',
    name: 'Crosschain USDC',
    symbol: 'USDC',
    decimals: 6,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/wanUSDC.png',
  },
  {
    chainId: 999,
    address: '0x0000000000000000000000000000000000000000',
    name: 'WAN',
    symbol: 'WWAN',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WAN.png',
  },
  {
    chainId: 999,
    address: '0x37e907f611CA55F10D32e3Af7407305Ee93B0A10',
    name: 'WAND',
    symbol: 'WAND',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WAND.png',
  },
  {
    chainId: 999,
    address: '0x830053DABd78b4ef0aB0FeC936f8a1135B68da6f',
    name: 'WASP',
    symbol: 'WASP',
    decimals: 18,
    logoURI: 'https://icons.wanswap.finance/icon/wanswap/WASP.png',
  },
];

const coinConfig = {
  888: mainSrc,
  999: testSrc,
};

export const defaultItem = {
  logoURI: defaultImg,
};

export default coinConfig;
