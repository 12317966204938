import React, { useState, memo, useMemo } from 'react';
import styled from 'styled-components';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import SingleCoinIcon from '../../components/Icon/Single';
import { SquareBtn } from '../../components/Button';
import { getCoinUrlByNameFn, commafy } from '../../utils';
import { ChevronDown } from 'react-feather';
import { BaseRow, RowBetween } from '../../components/Row';
import { WalletContext } from '../../utils/wallet';
import WarnDepositsIsNotEnough from '../../components/Content/WarnDepositsIsNotEnough';
import { HeaderContext } from '../Header';
import MobileTradeChild from '../../components/Content/MobilTradeChild';

const colorBlue = '#5B86E5';
const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';
const colorGreen = '#00A7C1';

const Border = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 1.25rem;
  position: relative;
`;
const ItemTitle = styled.div`
  padding: 1.25rem 1.25rem 0;
`;
const ItemLine = styled(RowBetween)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
`;
const Item = styled(BaseRow)`
  flex: 1;
  margin-bottom: 0.5rem;
  align-items: center;
`;
const ItemRight = styled(Item)`
  flex-direction: ${(props) => (props.row ? 'row' : 'row-reverse')};
  justify-content: end;
  color: ${colorFFFOpacity3};
`;
const Txt14 = styled.p`
  font-size: 0.875rem;
  color: ${(props) => (props.color ? props.color : '#fff')};
  align-items: center;
`;
const Txt12 = styled(Txt14)`
  font-size: 0.75rem;
`;
const HideContent = styled.div`
  border-top: 1px solid ${colorFFFOpacity3};
  padding: 0 1.25rem 0;
  display: flex;
  flex-direction: column;
`;
const UnfoldBtn = styled(SquareBtn)`
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 4px;
  border: 1px solid ${colorBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: ${(props) => (props.hasBlock ? 'visible' : 'hidden')};

  & .arrowIcon {
    transform: rotate(${(props) => (props.unfold ? '-180deg' : '0deg')});
  }
`;
const Btn = styled(SquareBtn)`
  border-radius: 8px;
  border: 1px solid;
  width: 5rem;
  margin: ${(props) => (props.hasMr ? '0 0.5rem 0 0' : '0')};
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HideBtn = styled(UnfoldBtn)`
  width: 3.875rem;
  height: 1.375rem;
  border: 1px solid ${colorFFFOpacity3};
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  margin: 0 auto;
  display: ${(props) => (props.hasBlock ? 'block' : 'none')};

  & .arrowIcon {
    // transition: all 0.2s ease-out;
    transform: rotate(${(props) => (props.unfold ? '-180deg' : '0deg')});
  }
`;

const TradeItem = memo((props) => {
  const {
    item,
    unfold,
    index,
    setCurIndexFn,
    setStartIndexFn,
    chooseDepositFn,
    setOpenDepositsModalFn,
    chooseWithdrawFn,
    setOpenWithdrawModalFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    setOpenStartModal1Fn,
  } = props;

  const { supportedPairs } = useStreamTx();

  const arr = useMemo(() => {
    return supportedPairs.filter((v) => v.fromToken === item.address);
  }, [item.address, supportedPairs]);

  const supportedList = useMemo(() => {
    return !arr[0] ? [] : unfold ? arr : [arr[0]];
  }, [arr, unfold]);

  const [showHideContent, setShowHideContent] = useState(false);

  const depositFn = () => {
    chooseDepositFn(item.address);
    setOpenDepositsModalFn(true);
  };
  const withdrawFn = () => {
    chooseWithdrawFn(item.address);
    setOpenWithdrawModalFn(true);
  };
  const hasWorking = useMemo(() => {
    return arr.find((v) => v.isWorking) ? true : false;
  }, [arr]);

  return (
    <WalletContext.Consumer>
      {(wallet) => (
        <Border>
          <ItemTitle>
            <ItemLine>
              <Item>
                <SingleCoinIcon
                  size={2}
                  url={getCoinUrlByNameFn(item.symbol, wallet.networkId)}
                />
                <Txt14>{item.symbol}</Txt14>
              </Item>
              <Item>
                <Btn
                  color={colorBlue}
                  width={'80px'}
                  hasMr={true}
                  onClick={depositFn}
                >
                  Deposit
                </Btn>
                <HeaderContext.Consumer>
                  {(simpleModeType) => (
                    <Btn
                      color={colorGreen}
                      width={'80px'}
                      disabled={Boolean(simpleModeType && hasWorking)}
                      onClick={() => {
                        if (simpleModeType && hasWorking) return;
                        withdrawFn();
                      }}
                    >
                      Withdraw
                    </Btn>
                  )}
                </HeaderContext.Consumer>
              </Item>
            </ItemLine>
            <ItemLine>
              <Item>
                <Txt14 color={colorBlue}>My Wallet</Txt14>&nbsp;
              </Item>
              <ItemRight>
                <Txt12 color={colorFFFOpacity3}>{item.symbol}</Txt12>
                <Txt14 fw={'bold'}>
                  {commafy(item?.balance?.toString())}&nbsp;
                </Txt14>
              </ItemRight>
            </ItemLine>
            <ItemLine>
              <Item>
                <Txt14 color={colorBlue}>My Deposit</Txt14>
                &nbsp;
              </Item>
              <ItemRight>
                <WarnDepositsIsNotEnough
                  arr={arr}
                  index={index}
                ></WarnDepositsIsNotEnough>
                <Txt12 color={colorFFFOpacity3}>{item.symbol}</Txt12>
                <Txt14 fw={'bold'}>
                  {commafy(item?.deposited?.toString())}&nbsp;
                </Txt14>
                &nbsp;
              </ItemRight>
            </ItemLine>
          </ItemTitle>
          {showHideContent && (
            <HideContent>
              {showHideContent &&
                supportedList.map((v, k) => (
                  <MobileTradeChild
                    key={k}
                    v={v}
                    k={k}
                    item={item}
                    index={index}
                    unfold={unfold}
                    hasBlock={arr.length !== 1 && !k}
                    setCurIndexFn={(i) => setCurIndexFn(i)}
                    setStartIndexFn={(i) => setStartIndexFn(i)}
                    setStartInfoFn={(v) => setStartInfoFn(v)}
                    setStartNumFn={(v) => setStartNumFn(v)}
                    setTimeFn={(v) => setTimeFn(v)}
                    setOpenStartModal1Fn={(v) => setOpenStartModal1Fn(v)}
                  ></MobileTradeChild>
                ))}
            </HideContent>
          )}
          <HideBtn
            hasBlock={supportedList.length !== 0}
            unfold={showHideContent}
            onClick={() => setShowHideContent(!showHideContent)}
          >
            <ChevronDown
              size={20}
              color={colorFFFOpacity3}
              className='arrowIcon'
            />
          </HideBtn>
        </Border>
      )}
    </WalletContext.Consumer>
  );
});

export default TradeItem;
