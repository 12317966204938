import React from 'react';
import styled, { css } from 'styled-components';
import { RowBetween, RowAround } from '../../components/Row';
// import Setting from '../../components/Setting';
import Menu from '../../components/Menu';
import WalletCom from '../../components/Wallet';
import { isMobile } from 'react-device-detect';
import logoSvg from '../../static/logo.svg';
import History from '../History';
import { isSupportedNetwork } from '../../utils/index';

const HeaderCon = styled(RowBetween)`
  width: 100%;
  align-items: center;
  margin: 30px 0 40px;

  ${isMobile &&
  css`
    margin: 1.25rem 0 1.125rem;
    padding: 0 0.9375rem;
  `}
`;

const Logo = styled.div`
  background: url(${logoSvg}) no-repeat;
  background-size: contain;
  width: 290px;
  height: 40px;

  ${isMobile &&
  css`
    width: 11.875rem;
  `}
`;

const Tools = styled(RowAround)`
  // flex: 1;
  align-items: center;
`;
const TopLine = styled(RowBetween)`
  flex: 1;
`;

const differ = (a, b) => {
  console.log(a, b);
  if (a !== b) return 1;
  return 0;
};

export const HeaderContext = React.createContext({}, differ);

function Header(props) {
  const { simpleModeType, setSimpleModeType, connectWallet, wallet } = props;

  return (
    <HeaderCon>
      {!isMobile ? (
        <Logo></Logo>
      ) : (
        <TopLine>
          <Logo></Logo>
          {wallet.connected ? <History></History> : null}
        </TopLine>
      )}
      {!isMobile && (
        <Tools>
          <WalletCom wallet={wallet} connectWallet={connectWallet} />
          {/* <Setting style={{marginRight: '16px'}} /> */}
          {wallet.connected && isSupportedNetwork(wallet.networkId) ? (
            <History></History>
          ) : null}
          <Menu
            simpleModeType={simpleModeType}
            setSimpleModeType={setSimpleModeType}
          />
        </Tools>
      )}
    </HeaderCon>
  );
}

export default Header;
