import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getTimeFn } from '../../utils';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import { SquareBtn } from '../Button';
import { isMobile } from 'react-device-detect';

const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';
const colorBlue = '#5B86E5';

const Btn = styled(SquareBtn)`
  border-radius: 8px;
  border: 1px solid;
  width: 80px;
  margin: ${(props) => (props.hasMr ? '0 12px 0 0' : '0')};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin: ${(props) => (props.hasMr ? '0 0 12px 0' : '0')};
    font-size: 12px;
	`}

  ${isMobile &&
  css`
    width: 48%;
    margin: ${(props) => (props.hasMr ? '0 0.5rem 0 0' : '0')} !important;
    font-size: 0.75rem;
  `}
`;
const StartUpdateBtn = (props) => {
  const { time } = props;
  const [updateTime, setUpdateTime] = useState(time);
  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTime(updateTime - 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [time, updateTime]);
  return <span>{getTimeFn(updateTime)}</span>;
};

const StartBtn = (props) => {
  const {
    info,
    stoping,
    index,
    setStartIndexFn,
    setStartInfoFn,
    setStartNumFn,
    setTimeFn,
    setOpenStartModal1Fn,
    setStopingFn,
  } = props;
  // console.log('info', info);
  const forbidden = info.name.includes('ZOO');
  const { userSessions } = useStreamTx();
  let userArr = [];
  userSessions.map((v) => (userArr = userArr.concat(Object.values(v))));
  const item = userArr.find(
    (v) => v.receiver.toLowerCase() === info.tradeAddress.toLowerCase()
  );
  const nowTime = new Date().getTime() / 1000;
  const checkShowTime =
    !!item &&
    !info.isWorking &&
    nowTime - item.endTime < 300 &&
    nowTime - item.endTime > 0 &&
    !item.dead;

  useEffect(() => {
    if (checkShowTime) {
      stoping && setStopingFn();
    }
  }, [checkShowTime, setStopingFn, stoping]);

  return (
    <React.Fragment>
      {checkShowTime ? (
        <Btn
          disabled={true}
          color={colorFFFOpacity3}
          width={'80px'}
          hasMr={true}
        >
          <StartUpdateBtn time={300 - nowTime + +item.endTime}></StartUpdateBtn>
        </Btn>
      ) : (
        <Btn
          disabled={info.isWorking || stoping || forbidden}
          color={!info.isWorking ? colorBlue : colorFFFOpacity3}
          width={'80px'}
          hasMr={true}
          onClick={() => {
            if (info.isWorking) return;
            setStartIndexFn(index);
            setStartInfoFn(info);
            setStartNumFn('');
            setTimeFn(0);
            setOpenStartModal1Fn(true);
          }}
        >
          Start
        </Btn>
      )}
    </React.Fragment>
  );
};

export default StartBtn;
