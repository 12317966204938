import React, { useEffect, useCallback, useState, memo } from 'react';
import Header, { HeaderContext } from './view/Header';
import Introduce from './view/Introduce';
import Content from './view/Content/index';
import useStreamTx from './modals/useStreamTx/useStreamTx';
import Wallet, { WalletContext } from './utils/wallet';
import styled, { css } from 'styled-components';
import ThemeProvider from './theme';
import Footer from './view/Footer';
import { isMobile } from 'react-device-detect';

import bg from './static/bg.svg';

const Con = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  ${isMobile &&
  css`
    padding: 0;
  `}
`;

const AppContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;

  ${isMobile &&
  css`
    padding: 0 0.9375rem;
  `}
`;

const App = memo((props) => {
  const [wallet, setWallet] = useState({
    address: '',
    web3: null,
    provider: null,
    connected: false,
    networkId: 888, // TODO: CHANGE TO 888 AFTER JUPITER FORK
    chainType: 'wan',
  });

  const { address, chainId, web3, connected } = wallet;

  const [simpleModeType, setSimpleModeType] = useState(true);

  const { updateWallet } = useStreamTx();

  // const [error, setError] = useState(false);

  const connectWallet = useCallback(() => {
    if (connected) {
      wallet.resetApp().then(() => {
        wallet.connect();
      });
    } else {
      wallet.connect();
    }
  }, [wallet, connected]);

  useEffect(() => {
    updateWallet(wallet);
    // setError(!(+chainId === 888 || +chainId === 999));
  }, [wallet, chainId, address, web3, updateWallet]);

  return (
    <Con>
      <Wallet setWallet={setWallet} wallet={wallet}></Wallet>
      <WalletContext.Provider value={wallet}>
        <ThemeProvider>
          <Header
            wallet={wallet}
            connectWallet={connectWallet}
            simpleModeType={simpleModeType}
            setSimpleModeType={setSimpleModeType}
          />
          <HeaderContext.Provider value={simpleModeType}>
            <AppContent>
              <Introduce />
              <Content />
            </AppContent>
          </HeaderContext.Provider>
          {isMobile && (
            <Footer
              wallet={wallet}
              connectWallet={connectWallet}
              simpleModeType={simpleModeType}
              setSimpleModeType={setSimpleModeType}
            />
          )}
        </ThemeProvider>
      </WalletContext.Provider>
    </Con>
  );
});

export default App;
