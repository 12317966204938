import styled from 'styled-components';

export const BaseRow = styled.div`
  display: flex;
`;

export const RowBetween = styled(BaseRow)`
  justify-content: space-between;
`;

export const RowAround = styled(BaseRow)`
  justify-content: space-around;
`;
