import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from './Base';
import { BaseRow, RowAround } from '../Row';
import { SquareBtn } from '../Button';
import { isMobile } from 'react-device-detect';
import limitTradeMinAmountConfig from '../../limitTradeMinAmountConfig';

const BigNumber = require('bignumber.js');
const colorBlue = '#5B86E5';

const StartTotal = styled(BaseRow)`
  flex-direction: column;
`;

const StartContent = styled(BaseRow)`
  padding: 0 32px;
  flex-direction: column;

  ${isMobile &&
  css`
    padding: 0 1.25rem;
  `}
`;

const Btn = styled(SquareBtn)`
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  height: auto;
  line-height: unset;
  padding: 20px 0;
  border: none;
  background: ${(props) => (props.disabled ? '#999999' : colorBlue)};
  color: ${(props) => (props.disabled ? '#C1C1C1' : '#ffffff')};
  margin-bottom: 10px;

  ${isMobile &&
  css`
    margin-bottom: 1.25rem;
  `}
`;

const TimeLine = styled(BaseRow)`
  justify-content: center;
  align-items: center;
`;

const Process = styled.div`
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  background: ${(props) => (props.active ? colorBlue : '#eee')};
  color: ${(props) => (props.active ? '#fff' : '#BDBDBD')};
`;

const ProcessLine = styled.div`
  width: 30px;
  height: 1px;
  background: #eee;
`;

const TimeTxt = styled.p`
  font-size: 14px;
  color: #999999;
  margin-bottom: 10px;
`;

const TimeAround = styled(RowAround)`
  margin-bottom: 20px;

  ${isMobile &&
  css`
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  `}
`;

const TimeBtn = styled(SquareBtn)`
  flex: 1;
  margin-right: 10px;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  background-color: ${(props) => (props.active ? '#5B86E5' : 'none')};
  border: 1px solid ${(props) => (props.active ? '#5B86E5' : '#999')};
  color: ${(props) => (props.active ? '#fff' : '#999')};

  ${isMobile &&
  css`
    flex: none;
    width: 48%;
    margin: 0 0 10px 0;
  `}
`;

const TimeInp = styled.input`
  flex: 1;
  margin-right: 10px;
  border-radius: 8px;
  border: 1px solid #999;
  font-size: 14px;
  color: #999;
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding: 0 16px;

  ::placeholder {
    color: #999;
  }

  ${isMobile &&
  css`
    flex: none;
    width: 48%;
    margin: 0 0 10px 0;
  `}
`;

const Warn = styled.p`
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 400;
  color: #f05f5f;

  ${isMobile &&
  css`
    padding: 1.25rem 1.25rem 0;
  `}
`;

const list = [
  { time: 12, value: '12 hour' },
  { time: 24, value: '1 day' },
  { time: 24 * 7, value: '7 days' },
  { time: 24 * 30, value: '30 days' },
];

export default function StartModal2({
  time,
  open,
  title,
  info,
  startNum,
  setTimeFn,
  nextFn,
  closeFn,
}) {
  const [inpHour, setInpHour] = useState('');
  const [inpDay, setInpDay] = useState('');
  const [curIndex, setCurIndex] = useState(-1);
  const [isLimit, setIsLimit] = useState(false);
  const [limitNum, setLimitNum] = useState(0);

  useEffect(() => {
    setInpHour('');
    setInpDay('');
    setCurIndex(-1);
    setIsLimit(false);
  }, [open]);

  useEffect(() => {
    if (!open) return;
    const limitItem = limitTradeMinAmountConfig.find(
      (v) =>
        v.address.toLocaleLowerCase() === info.fromToken.toLocaleLowerCase()
    );
    if (!limitItem) {
      setIsLimit(false);
      setLimitNum(0);
      return;
    }
    const per = new BigNumber(startNum).div(time).times(3600);
    if (per.lt(limitItem.limitAmount)) {
      setIsLimit(true);
      setLimitNum(limitItem.limitAmount);
    } else {
      setIsLimit(false);
      setLimitNum(0);
    }
  }, [info, startNum, time, open]);

  const chooseFn = (t, i) => {
    const time = t * 60 * 60;
    setTimeFn(time);
    const curDay = Math.floor(time / 60 / 60 / 24);
    setInpDay(curDay === 0 ? '' : String(curDay));
    const curHour = Math.floor((time - curDay * 24 * 60 * 60) / 60 / 60);
    setInpHour(curHour === 0 ? '' : String(curHour));
    setCurIndex(i);
  };
  const inpHourFn = (e) => {
    let h = e?.target?.value;
    if (+h !== Number(h)) return;
    if (!h) {
      setInpHour('');
      h = 0;
    } else {
      h = parseInt(h);
      setInpHour(h);
    }
    const sTime = (inpDay * 24 + h) * 60 * 60;
    setTimeFn(sTime);
    setCurIndex(list.findIndex((v) => v.time * 60 * 60 === sTime));
  };
  const inpDayFn = (e) => {
    let d = e?.target?.value;
    if (+d !== Number(d)) return;
    if (!d) {
      setInpDay('');
      d = 0;
    } else {
      d = parseInt(d);
      setInpDay(d);
    }
    let sTime = (inpHour + d * 24) * 60 * 60;
    setTimeFn(sTime);
    setCurIndex(list.findIndex((v) => v.time * 60 * 60 === sTime));
  };

  return (
    <BaseModal title={title} open={open} closeFn={closeFn}>
      <StartTotal>
        <StartContent>
          <TimeTxt>Quick Choose</TimeTxt>
          <TimeAround>
            {list.map((v, k) => (
              <TimeBtn
                style={{
                  margin: k === list.length - 1 ? 0 : '',
                }}
                key={k}
                onClick={() => chooseFn(v.time, k)}
                active={curIndex === k}
              >
                {v.value}
              </TimeBtn>
            ))}
          </TimeAround>
          <TimeTxt>Customize</TimeTxt>
          <TimeAround>
            <TimeInp
              value={inpDay}
              onChange={(e) => inpDayFn(e)}
              placeholder='Day'
            />
            <TimeInp
              value={inpHour}
              onChange={(e) => inpHourFn(e)}
              placeholder='Hour'
            />
          </TimeAround>
          <Btn
            disabled={!time || time > 365 * 24 * 60 * 60}
            onClick={() => {
              nextFn();
            }}
          >
            Next
          </Btn>
        </StartContent>
        <TimeLine>
          <Process>1</Process>
          <ProcessLine />
          <Process active={true}>2</Process>
          <ProcessLine />
          <Process>3</Process>
        </TimeLine>
        {isLimit && (
          <Warn>{`Must >= ${limitNum} ${
            info.name.split('->')[0]
          } per hour.`}</Warn>
        )}
      </StartTotal>
    </BaseModal>
  );
}
