import React, { useState, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import useStreamTx from '../../modals/useStreamTx/useStreamTx';
import CountUp from 'react-countup';
import { isMobile } from 'react-device-detect';

const colorFFFOpacity3 = 'rgba(255, 255, 255, 0.3)';

const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  ${isMobile &&
  css`
    justify-content: end;
  `}
`;

const Txt = styled.p`
  font-size: ${(props) => (props.fs ? props.fs : '16px')};
  color: ${(props) => (props.color ? props.color : '#fff')};
  padding: ${(props) => (props.pd ? props.pd : '0')};
  font-weight: ${(props) => (props.fw ? props.fw : 'normal')};
`;
const Txt16 = styled(Txt)`
  font-size: 16px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    font-size: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 14px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
  `}
`;
const SymbolTxt = styled(Txt16)`
  word-break: normal;
`;
const Txt20 = styled(Txt)`
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSupLarge`
    font-size: 20px;
  `}

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 16px;
  `}

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
  `}
`;
const Count = (props) => {
  const { paid, curPaid } = props;
  return (
    <CountUp
      start={parseFloat(curPaid)}
      end={parseFloat(paid)}
      duration={5}
      decimals={4}
      separator={','}
    ></CountUp>
  );
};
const PaidUpdate = (props) => {
  const { streamRate, startTime, stopTime, index, item } = props;
  const { supportedTokens } = useStreamTx();
  const [paid, setPaid] = useState(
    (+streamRate / 10 ** +supportedTokens[index].decimals) *
      (Date.now() / 1000 - startTime)
  );
  const [curPaid, setCurPaid] = useState(paid);
  useEffect(() => {
    const timer = setInterval(() => {
      setCurPaid(paid);
      if (+stopTime < Date.now() / 1000) {
        const sPaid =
          (+streamRate / 10 ** +supportedTokens[index].decimals) *
          (stopTime - startTime);
        setPaid(sPaid > 0 ? sPaid : '-');
      } else {
        const sPaid =
          (+streamRate / 10 ** +supportedTokens[index].decimals) *
          (Date.now() / 1000 - startTime);
        setPaid(sPaid > 0 ? sPaid : '-');
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [streamRate, startTime, stopTime, supportedTokens, index, paid]);
  return (
    <RowBetween>
      <Txt20 fw={'bold'} color={paid === '-' ? colorFFFOpacity3 : ''}>
        {paid === '-' ? (
          <span>-</span>
        ) : (
          <Count curPaid={curPaid} paid={paid} />
        )}
        &nbsp;
      </Txt20>
      <SymbolTxt color={colorFFFOpacity3}>{item.symbol}</SymbolTxt>
    </RowBetween>
  );
};
const PaidContent = (props) => {
  const { tradeAddress, index, curItem } = props;
  const { userSessions } = useStreamTx();
  const item = useMemo(() => {
    let userArr = [];
    userSessions.map((v) => (userArr = userArr.concat(Object.values(v))));
    const item = userArr.find(
      (v) => v.receiver.toLowerCase() === tradeAddress.toLowerCase()
    );
    return item;
  }, [tradeAddress, userSessions]);
  if (!userSessions.length)
    return (
      <Txt20 fw={'bold'} color={colorFFFOpacity3}>
        -
      </Txt20>
    );
  if (!item)
    return (
      <Txt20 fw={'bold'} color={colorFFFOpacity3}>
        -
      </Txt20>
    );
  if (!item.enable)
    return (
      <Txt20 fw={'bold'} color={colorFFFOpacity3}>
        -
      </Txt20>
    );
  return (
    <PaidUpdate
      streamRate={item.streamRate}
      item={curItem}
      startTime={item.startTime}
      stopTime={item.endTime}
      index={index}
    ></PaidUpdate>
  );
};

export default PaidContent;
