import { createModel } from 'hox';
import { useCallback, useEffect, useMemo, useState } from 'react';
const { aggregate } = require('@makerdao/multicall');
const BigNumber = require('bignumber.js');
const streamAbi = require('./stream.abi.json');
const erc20Abi = require('./erc20.abi.json');
const carAbi = require('./car.abi.json');

const RPC_URL = {
  888: 'https://gwan-ssl.wandevs.org:56891',
  999: 'https://gwan-ssl.wandevs.org:46891',
};

const MULTICALL_ADDRESS = {
  888: '0xBa5934Ab3056fcA1Fa458D30FBB3810c3eb5145f',
  999: '0x14095a721Dddb892D6350a777c75396D634A7d97',
};

const STREAM_ADDR = {
  888: '0x911a82BF8D8518Af35ACd09EeF9BDe90520855aA',
  999: '0xb445e2dE3E007e844da3443fE6b8eaC7c5D4FC2F',
};

const MANAGER_ADDR = {
  888: '0x086f117D5CF1542E50f100A78ffDd5E67Cf8E131',
  999: '0x5C55ff5e89a5744006dfA6e067e4b9E2a58D80c7',
};

const WWAN = {
  888: '0xdabD997aE5E4799BE47d6E69D9431615CBa28f48',
  999: '0x916283cc60fdaf05069796466af164876e35d21f',
};

const WASP = {
  888: '0x8B9F9f4aA70B1B0d586BE8aDFb19c1Ac38e05E9a',
  999: '0x830053DABd78b4ef0aB0FeC936f8a1135B68da6f',
};

const MAX =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const MIN = '0xffffffffffffffffffffffffffffff';

const INIT_TOKENS = {
  888: [
    {
      symbol: 'WAN',
      decimals: 18,
      address: '0x0000000000000000000000000000000000000000',
      balance: '0',
      deposited: '0',
      approved: true,
    },
    {
      symbol: 'WASP',
      decimals: 18,
      address: '0x8B9F9f4aA70B1B0d586BE8aDFb19c1Ac38e05E9a',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'WASPv2',
      decimals: 18,
      address: '0x924fd608bf30dB9B099927492FDA5997d7CFcb02',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'WAND',
      decimals: 18,
      address: '0x230f0C01b8e2c027459781E6a56dA7e1876EFDbe',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanUSDT',
      decimals: 6,
      address: '0x11e77E27Af5539872efEd10abaA0b408cfd9fBBD',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'ZOO',
      decimals: 18,
      address: '0x6e11655d6aB3781C6613db8CB1Bc3deE9a7e111F',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanLTC',
      decimals: 8,
      address: '0xd8e7bd03920BA407D764789B11DD2B5EAeE0961e',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanXRP',
      decimals: 6,
      address: '0xf665E0e3E75D16466345E1129530ec28839EfaEa',
      balance: '0',
      deposited: '0',
      approved: false,
    },

    {
      symbol: 'wanDOT',
      decimals: 10,
      address: '0x52f44783BdF480e88C0eD4cF341A933CAcfDBcaa',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanDOGE',
      decimals: 8,
      address: '0xD3a33C6fEa7F785DdC0915f6A76919C11AbdED45',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanUSDC',
      decimals: 6,
      address: '0x52A9CEA01c4CBDd669883e41758B8eB8e8E2B34b',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanETH',
      decimals: 18,
      address: '0xE3aE74D1518A76715aB4C7BeDF1af73893cd435A',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanBTC',
      decimals: 8,
      address: '0x50c439B6d602297252505a6799d84eA5928bCFb6',
      balance: '0',
      deposited: '0',
      approved: false,
    },
  ],
  999: [
    {
      symbol: 'WAN',
      decimals: 18,
      address: '0x0000000000000000000000000000000000000000',
      balance: '0',
      deposited: '0',
      approved: true,
    },
    {
      symbol: 'WASP',
      decimals: 18,
      address: '0x830053DABd78b4ef0aB0FeC936f8a1135B68da6f',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'WAND',
      decimals: 18,
      address: '0x37e907f611CA55F10D32e3Af7407305Ee93B0A10',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'USDT.test',
      decimals: 6,
      address: '0x0f6be49eB9d86f97dE0EB759c856bFb0db8316f7',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'wanETH',
      decimals: 18,
      address: '0x48344649B9611a891987b2Db33fAada3AC1d05eC',
      balance: '0',
      deposited: '0',
      approved: false,
    },
    {
      symbol: 'BTC.test',
      decimals: 8,
      address: '0x3c653971ffc0794CB2fC5DF5D47576BEdCE149B3',
      balance: '0',
      deposited: '0',
      approved: false,
    },
  ],
};

const isSupportedNetwork = (chainId) => {
  return [888, 999].includes(Number(chainId));
};

let time = 0;
function useStreamTx() {
  const [supportedTokens, setSupportedTokens] = useState(INIT_TOKENS[888]);
  const [supportedPairs, setSupportedPairs] = useState([]);
  const [currentPairs, setCurrentPairs] = useState([]);
  const [wallet, updateWallet] = useState({});
  const [userSessions, setUserSessions] = useState([]);
  const [userAssets, setUserAssets] = useState({});
  const [updater, setUpdater] = useState(0);
  const [streamApproved, setStreamApproved] = useState();
  const [updateApprove, setUpdateApprove] = useState(0);
  const [penddingTime, setPenddingTime] = useState(0);
  const chainId = wallet.networkId;
  const address = wallet.address;
  // const address = TEMPTATION_ADDR[chainId];
  const web3 = wallet.web3;

  const conf = useMemo(() => {
    return {
      rpcUrl: RPC_URL[chainId],
      multicallAddress: MULTICALL_ADDRESS[chainId],
    };
  }, [chainId]);

  // get support trade pairs
  useEffect(() => {
    if (!isSupportedNetwork(chainId)) {
      return;
    }
    time = new Date().getTime();
    setPenddingTime(time);
  }, [chainId]);
  useEffect(() => {
    if (!isSupportedNetwork(chainId)) {
      return;
    }
    let calls = [
      {
        target: MANAGER_ADDR[chainId],
        call: ['getCarsInfo()(string[],address[],address[],address[])'],
        returns: [
          ['names', (val) => val],
          ['fromTokens', (val) => val],
          ['toTokens', (val) => val],
          ['tradeAddresses', (val) => val],
        ],
      },
    ];
    aggregate(calls, conf).then((ret) => {
      console.log(chainId, time, penddingTime, time !== penddingTime, address);
      if (time !== penddingTime && address) return;

      let pairs = ret.results.transformed.names.map((v, i) => {
        return {
          name: v,
          fromToken: ret.results.transformed.fromTokens[i],
          toToken: ret.results.transformed.toTokens[i],
          tradeAddress: ret.results.transformed.tradeAddresses[i],
          isWorking: false,
        };
      });

      pairs = pairs.map((v) => {
        if (v.fromToken.toLowerCase() === WWAN[chainId].toLowerCase()) {
          v.fromToken = '0x0000000000000000000000000000000000000000';
          v.name = v.name.replace('WWAN', 'WAN');
        }

        if (v.toToken.toLowerCase() === WWAN[chainId].toLowerCase()) {
          v.toToken = '0x0000000000000000000000000000000000000000';
          v.name = v.name.replace('WWAN', 'WAN');
        }
        return v;
      });

      if (!pairs || pairs.length === 0) {
        return;
      }

      // console.log('!pairs', chainId, pairs);

      setCurrentPairs(pairs);
      setSupportedPairs(pairs);
    });
  }, [address, chainId, conf, penddingTime]);

  // update token balance and approve
  useEffect(() => {
    if (
      !isSupportedNetwork(chainId) ||
      !address ||
      !web3 ||
      !STREAM_ADDR[chainId] ||
      !INIT_TOKENS[chainId] ||
      currentPairs.length === 0
    ) {
      return;
    }
    const func = async (url) => {
      let calls = [];
      calls = calls.concat(
        INIT_TOKENS[chainId].map((v) => {
          if (v.symbol === 'WAN') {
            return {
              call: ['getEthBalance(address)(uint256)', address],
              returns: [
                [
                  v.symbol + '_balance',
                  (val) => new BigNumber(val.toString()).div(1e18),
                ],
              ],
            };
          } else {
            return {
              target: v.address,
              call: ['balanceOf(address)(uint256)', address],
              returns: [
                [
                  v.symbol + '_balance',
                  (val) => new BigNumber(val.toString()).div(10 ** v.decimals),
                ],
              ],
            };
          }
        })
      );

      calls = calls.concat(
        INIT_TOKENS[chainId]
          .filter((v) => v.symbol !== 'WAN')
          .map((v) => {
            return {
              target: v.address,
              call: [
                'allowance(address,address)(uint256)',
                address,
                STREAM_ADDR[chainId],
              ],
              returns: [
                [
                  v.symbol + '_allowance',
                  (val) => new BigNumber(val.toString()),
                ],
              ],
            };
          })
      );

      calls = calls.concat(
        INIT_TOKENS[chainId].map((v) => {
          return {
            target: STREAM_ADDR[chainId],
            call: [
              'getUserRealTimeAsset(address,address)(uint256)',
              address,
              v.address,
            ],
            returns: [
              [
                v.symbol + '_deposited',
                (val) => new BigNumber(val.toString()).div(10 ** v.decimals),
              ],
            ],
          };
        })
      );

      calls = calls.concat(
        currentPairs.map((v) => {
          return {
            target: STREAM_ADDR[chainId],
            call: [
              'isWorking(address,address,address)(bool)',
              address,
              v.fromToken,
              v.tradeAddress,
            ],
            returns: [[v.name + '_isWorking', (val) => val]],
          };
        })
      );

      // console.log("calls", calls);
      let ret = await aggregate(calls, conf);
      // const blockNumber = Number(ret.results.blockNumber);
      let tokens = INIT_TOKENS[chainId];
      tokens = tokens.map((v) => {
        v.balance = ret.results.transformed[v.symbol + '_balance'];
        v.deposited = ret.results.transformed[v.symbol + '_deposited'];
        if (v.symbol !== 'WAN') {
          v.approved = ret.results.transformed[v.symbol + '_allowance'].gt(MIN);
        }
        return v;
      });
      // console.log("token balance", tokens);
      setSupportedTokens(tokens);

      let pairs = currentPairs;
      pairs = pairs.map((v) => {
        v.isWorking = ret.results.transformed[v.name + '_isWorking'];
        return v;
      });
      // console.log('!setSupportedPairs', chainId, pairs);
      setSupportedPairs(pairs);
    };
    func();
  }, [address, chainId, web3, updater, conf, currentPairs]);

  const stream = useMemo(() => {
    if (
      !isSupportedNetwork(chainId) ||
      !address ||
      !web3 ||
      !STREAM_ADDR[chainId]
    ) {
      return;
    }
    return new web3.eth.Contract(streamAbi, STREAM_ADDR[chainId]);
  }, [address, chainId, web3]);

  // get assets
  useEffect(() => {
    if (!address || !stream) {
      return;
    }

    const func = async () => {
      const assets = await stream.methods.getUserAssets(address).call();
      setUserAssets(assets);
    };

    func();
  }, [chainId, stream, updater, address]);

  // get session
  useEffect(() => {
    if (
      !stream ||
      !address ||
      !userAssets.assets ||
      userAssets.assets.length === 0
    ) {
      return;
    }
    const func = async () => {
      let sessions = [];
      for (let i = 0; i < userAssets.assets.length; i++) {
        let ss = await stream.methods
          .getUserAssetSessions(address, userAssets.assets[i])
          .call();
        // console.log("getUserAssetSessions", ss);
        let ssInfo = await Promise.all(
          ss.map(async (v) => {
            let info = await stream.methods.sessionInfo(v).call();
            let pendingAmount = await stream.methods.pendingAmount(v).call();
            info.pendingAmount = pendingAmount;
            return {
              [v]: info,
            };
          })
        );
        sessions = sessions.concat(ssInfo);
      }
      // console.log("sessions", sessions);
      setUserSessions(sessions);
    };
    func();
  }, [userAssets, stream, address]);

  // check approve
  useEffect(() => {
    if (
      !isSupportedNetwork(chainId) ||
      !address ||
      !web3 ||
      !WASP[chainId] ||
      !WWAN[chainId] ||
      !STREAM_ADDR[chainId]
    ) {
      return;
    }

    const func = async () => {
      const wasp = new web3.eth.Contract(erc20Abi, WASP[chainId]);
      const wwan = new web3.eth.Contract(erc20Abi, WWAN[chainId]);

      let allowance = await wasp.methods
        .allowance(address, STREAM_ADDR[chainId])
        .call();
      if (!new BigNumber(allowance).gt(MIN)) {
        setStreamApproved(false);
        return;
      }
      allowance = await wwan.methods
        .allowance(address, STREAM_ADDR[chainId])
        .call();
      if (!new BigNumber(allowance).gt(MIN)) {
        setStreamApproved(false);
        return;
      }
      setStreamApproved(true);
    };
    func();
  }, [address, chainId, web3, updateApprove]);

  const deposit = useCallback(() => {
    return async (tokenObj, _amount) => {
      if (!address || !stream) {
        return;
      }
      let ret;
      let token = tokenObj.address;
      let amount =
        '0x' +
        new BigNumber(_amount)
          .multipliedBy(10 ** tokenObj.decimals)
          .toString(16)
          .split('.')[0];
      console.log('deposit', token, amount);
      if (tokenObj.symbol === 'WAN') {
        ret = await stream.methods
          .deposit(token, amount)
          .send({ from: address, value: amount });
        console.log('deposit', ret);
      } else {
        ret = await stream.methods
          .deposit(token, amount)
          .send({ from: address });
        console.log('deposit', ret);
      }

      setUpdater(Date.now());
      return ret;
    };
  }, [address, stream]);

  const withdraw = useCallback(() => {
    return async (tokenObj, _amount, autoUnwrap) => {
      if (!address || !stream) {
        return;
      }
      let token = tokenObj.address;
      let amount =
        '0x' +
        new BigNumber(_amount)
          .multipliedBy(10 ** tokenObj.decimals)
          .toString(16)
          .split('.')[0];
      let ret = await stream.methods
        .withdraw(token, amount, autoUnwrap)
        .send({ from: address });
      console.log('deposit', ret);
      setUpdater(Date.now());
      return ret;
    };
  }, [address, stream]);

  const start = useCallback(() => {
    return async (pair, _amount, period, colIndex) => {
      if (!address || !stream) {
        return;
      }

      let tokenObj = supportedTokens.find((v) => v.address === pair.fromToken);

      let amount =
        '0x' +
        new BigNumber(_amount)
          .multipliedBy(10 ** tokenObj.decimals)
          .toString(16)
          .split('.')[0];

      let ret = await stream.methods
        .startStream(
          pair.fromToken,
          amount,
          pair.tradeAddress,
          period,
          colIndex
        )
        .send({ from: address });
      console.log('start', ret);
      setUpdater(Date.now());
      return ret;
    };
  }, [address, stream, supportedTokens]);

  const stop = useCallback(() => {
    return async (pair) => {
      if (!address || !stream) {
        return;
      }

      let ret = await stream.methods
        .stopStream(pair.fromToken, pair.tradeAddress)
        .send({ from: address });
      console.log('stop', ret);
      setUpdater(Date.now());
      return ret;
    };
  }, [address, stream]);

  const transfer = useCallback(() => {
    return async (token, to, amount) => {
      if (!address || !stream) {
        return;
      }

      let ret = await stream.methods
        .transferAsset(token, to, amount)
        .send({ from: address });
      console.log('transferAsset', ret);
      setUpdater(Date.now());
      return ret;
    };
  }, [address, stream]);

  const approve = useCallback(() => {
    return async (tokenAddress) => {
      if (
        !isSupportedNetwork(chainId) ||
        !address ||
        !web3 ||
        !STREAM_ADDR[chainId]
      ) {
        return;
      }

      const token = new web3.eth.Contract(erc20Abi, tokenAddress);
      let allowance = await token.methods
        .allowance(address, STREAM_ADDR[chainId])
        .call();
      if (!new BigNumber(allowance).gt(MIN)) {
        await token.methods
          .approve(STREAM_ADDR[chainId], MAX)
          .send({ from: address });
      }

      setUpdateApprove(Date.now());
    };
  }, [address, chainId, web3]);

  const collateral = useCallback(() => {
    return async (pair, _amount, period, colIndex) => {
      console.log('!collateral');
      if (!address || !stream || !isSupportedNetwork(chainId)) {
        return;
      }

      let tokenObj = supportedTokens.find((v) => v.address === pair.fromToken);

      let amount =
        '0x' +
        new BigNumber(_amount)
          .multipliedBy(10 ** tokenObj.decimals)
          .toString(16)
          .split('.')[0];

      let from = pair.fromToken;
      if (from === '0x0000000000000000000000000000000000000000') {
        from = WWAN[chainId];
      }
      console.log('!getCollateral', from, amount, period, colIndex);
      let ret = await stream.methods
        .getCollateral(from, amount, period, colIndex)
        .call();
      return ret[1] / 1e18;
    };
  }, [address, stream, chainId, supportedTokens]);

  const getHistory = useCallback(() => {
    return async (tradeAddress) => {
      if (!address || !isSupportedNetwork(chainId) || !web3) {
        return;
      }

      let car = new web3.eth.Contract(carAbi, tradeAddress);

      let count = await car.methods.getHistoryCount(address).call();

      let historyIds = await car.methods
        .getHistoryIds(
          address,
          count > 50 ? count - 50 : 0,
          count > 50 ? 50 : count
        )
        .call();

      let calls = historyIds.map((id, i) => {
        return {
          target: tradeAddress,
          call: ['historyInfo(uint256)(address,address,uint,uint,uint)', id],
          returns: [
            ['sellToken_' + i, (val) => val],
            ['buyToken_' + i, (val) => val],
            ['sellAmount_' + i, (val) => val.toString()],
            ['buyAmount_' + i, (val) => val.toString()],
            [
              'startTime_' + i,
              (val) => new Date(val.toString() * 1000).getTime().toString(),
            ],
          ],
        };
      });

      let ret = await aggregate(calls, conf);

      let historys = [];
      Object.keys(ret.results.transformed).map((v) => {
        let pieces = v.split('_');
        let index = pieces[1];
        let key = pieces[0];
        if (!historys[index]) {
          historys[index] = {};
        }
        historys[index][key] = ret.results.transformed[v];
        return v;
      });

      historys = historys.map((v) => {
        if (v.sellToken.toLowerCase() === WWAN[chainId].toLowerCase()) {
          v.sellToken = '0x0000000000000000000000000000000000000000';
        }

        if (v.buyToken.toLowerCase() === WWAN[chainId].toLowerCase()) {
          v.buyToken = '0x0000000000000000000000000000000000000000';
        }

        let sellToken = INIT_TOKENS[chainId].filter(
          (t) => t.address.toLowerCase() === v.sellToken.toLowerCase()
        );
        if (sellToken.length > 0) {
          v.sellToken = sellToken[0].symbol;
          v.sellAmount = v.sellAmount / 10 ** sellToken[0].decimals;
        }

        let buyToken = INIT_TOKENS[chainId].filter(
          (t) => t.address.toLowerCase() === v.buyToken.toLowerCase()
        );
        if (sellToken.length > 0) {
          v.buyToken = buyToken[0].symbol;
          v.buyAmount = v.buyAmount / 10 ** buyToken[0].decimals;
        }

        v.price = v.buyAmount / v.sellAmount;
        return v;
      });

      return historys.reverse();
    };
  }, [address, chainId, web3, conf]);

  // timer trigger
  useEffect(() => {
    const timer = setInterval(() => {
      setUpdater(Date.now());
    }, 10 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return {
    supportedPairs,
    supportedTokens,

    updateWallet,

    STREAM_ADDR: STREAM_ADDR[chainId],
    WWAN: WWAN[chainId],
    WASP: WASP[chainId],
    userSessions,
    streamApproved,

    deposit: deposit(),
    withdraw: withdraw(),
    start: start(),
    stop: stop(),
    transfer: transfer(),
    approve: approve(),
    collateral: collateral(),
    getHistory: getHistory(),
  };
}

export default createModel(useStreamTx);
